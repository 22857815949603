import { genericRewritesPaths } from '@brand/search/generic-rewrites'
import type { ParsedUrlQuery } from 'querystring'
import { SearchRouteUtils, searchRouteUtils } from '../search-route-utils'
import { extractRefinementMap } from './extract-refinement-map'
import { getSlugForRefinementMap } from './get-slug-for-refinement-map'
import { getBedBathFilteredRefinements } from './get-bed-bath-filtered-refinements'

const knownRouteParams = [
  'county',
  'location',
  'hood',
  'poi',
  'propertyTypes',
  'refinements',
  'school',
  'schoolDistrict',
  'state',
  'zipCode',
]

/**
 * Get search URL for the current route params.
 *
 * Because we use rewrites to do all the routing within search,
 * we'll never have a `params` on Next's router or context.
 * *Everything* ends up as a flat object (`ParsedUrlQuery`)
 * in `ctx.query` or `router.query`.
 *
 * @param rawQuery
 * @param possibleRewritePath
 */
export function getSearchPageUrl(
  rawQuery: ParsedUrlQuery,
  possibleRewritePath?: string,
  withDynamicPriceFilters?: boolean
): string | null {
  const query = { ...rawQuery }

  if (query.page) {
    let ok = true
    // Validate that `page` can be used as an actual number.
    if (typeof query.page === 'string') {
      ok = !isNaN(parseInt(query.page))
    }

    if (!ok || query.page === '1') {
      delete query.page
    }
  }

  const unknownQueryParams = new URLSearchParams()
  let queryParamString = ''
  Object.entries(query).forEach(([key, value]) => {
    if (
      !knownRouteParams.includes(key) &&
      typeof value === 'string' &&
      key !== 'page'
    ) {
      unknownQueryParams.set(key, value)
    }
  })

  if ([...unknownQueryParams].length > 0) {
    queryParamString = `?${unknownQueryParams.toString()}`
  }

  if (
    (possibleRewritePath &&
      genericRewritesPaths.includes(possibleRewritePath)) ||
    possibleRewritePath?.includes('rooms-for-rent')
  ) {
    return `${possibleRewritePath}${queryParamString}`
  }

  if (typeof rawQuery.refinements === 'string') {
    // parse refinements to ensure we only show valid refinements
    const parsedRefinementsMap = extractRefinementMap(
      rawQuery.refinements,
      withDynamicPriceFilters
    )

    // filter beds or baths if all of them are selected
    const filteredRefinementMap =
      getBedBathFilteredRefinements(parsedRefinementsMap)
    // handle custom serialization back to the slug
    query.refinements = getSlugForRefinementMap(filteredRefinementMap)

    if (query.refinements === '') {
      delete query.refinements
    }
  }

  try {
    const matchedPath = searchRouteUtils.getSearchPathForParams(
      SearchRouteUtils.queryToSearchParams(query)
    )
    if (matchedPath) {
      return matchedPath + queryParamString
    }
  } catch (e) {}

  return null
}
