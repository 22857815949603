import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
} from '@rentpath/core-components'
import clsx from 'clsx'
import type { ElementType, ForwardedRef, ReactNode } from 'react'
import React, { forwardRef } from 'react'

import styles from './button.module.css'

export type ButtonOwnProps = {
  /**
   * Must provide [Phrasing content](https://www.w3.org/TR/2011/WD-html5-20110525/content-models.html#phrasing-content-0)
   * but there must be no [Interactive content](https://www.w3.org/TR/2011/WD-html5-20110525/content-models.html#interactive-content-0).
   */
  children?: ReactNode
  size?: 'sm' | 'md' | 'lg'
  variant?:
    | 'primary'
    | 'outlined'
    | 'inverted'
    | 'basic'
    | 'anchor'
    | 'filter'
    | 'pill'
    | 'agTertiaryRedesign'
    | 'rentSrpSecondary'
    | 'rentSrpTertiary'
  fluid?: boolean
  rounded?: boolean | 'full'
}

const ButtonDefaultElement = 'button'

export const Button: PolymorphicForwardRefExoticComponent<
  ButtonOwnProps,
  typeof ButtonDefaultElement
> = forwardRef(function Button<
  T extends ElementType = typeof ButtonDefaultElement
>(
  {
    as,
    size,
    variant,
    rounded,
    fluid,
    className,
    ...restProps
  }: PolymorphicPropsWithoutRef<ButtonOwnProps, T>,
  ref: ForwardedRef<Element>
) {
  const Element: ElementType = as || ButtonDefaultElement

  return (
    <Element
      ref={ref}
      className={clsx(
        styles.base,
        typeof variant === 'string' && styles[variant],
        {
          [styles.sizeSm]: size === 'sm',
          [styles.sizeMd]: size === 'md',
          [styles.sizeLg]: size === 'lg',
          [styles.rounded]: rounded === true,
          [styles.circle]: rounded === 'full',
          [styles.fluid]: fluid === true,
        },
        className
      )}
      {...restProps}
    />
  )
})
