'use client'

import React, { useState } from 'react'

import type { ReactNode } from 'react'
import type { TestIdProp } from '../../types'

export type SkipLinkProps = {
  children?: ReactNode
  className?: string
  id: string
  selector?: string
  leftPositionOnAppearance?: string
} & TestIdProp

export function SkipLink({
  children = 'Skip to Content (Press Enter)',
  className,
  'data-tid': tid,
  id,
  leftPositionOnAppearance = '0px',
  selector,
}: SkipLinkProps) {
  const [leftPosition, setLeftPosition] = useState('-1000px')

  const handleScroll = (): void => {
    let element

    if (selector) {
      element = document.querySelector<HTMLElement>(selector)
    } else {
      element = id.startsWith('#')
        ? document.querySelector<HTMLElement>(id)
        : document.getElementById(id)
    }

    element?.focus()
    setLeftPosition('-1000px')
  }

  const handleKeyPress = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleScroll()
    }
  }
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    handleScroll()
  }
  const handleBlur = () => setLeftPosition('-1000px')
  const handleFocus = () => setLeftPosition(leftPositionOnAppearance)

  return (
    <a
      data-tid={tid}
      onFocus={handleFocus}
      onBlur={handleBlur}
      className={className}
      style={{
        position: 'absolute',
        left: leftPosition,
      }}
      href={id}
      onClick={handleClick}
      onKeyDown={handleKeyPress}
    >
      {children}
    </a>
  )
}
