'use client'

import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { useCallback } from 'react'
import { useAtomValue } from 'jotai'
import { yieldOrContinue } from 'main-thread-scheduling'
import { useIsLoggedIn, userTotalSavedSearches } from '../../user/user.store'
import { useOpenModal } from '../../modals/use-open-modal'
import { ModalName } from '../../modals/modals.config'
import styles from '@brand/slots/page-header/drawer-menu/drawer-menu.module.css'

interface SavedSearchesLinkProps {
  onClick?: () => void
}

export function SavedSearchesLink(props: SavedSearchesLinkProps) {
  const { onClick } = props
  const router = useRouter()
  const isLoggedIn = useIsLoggedIn()
  const openModal = useOpenModal()
  const savedSearchesCount = useAtomValue(userTotalSavedSearches)

  const handleClick = useCallback(
    async function handleClick(e: React.MouseEvent<HTMLAnchorElement>) {
      if (!isLoggedIn) {
        e.preventDefault()
        await yieldOrContinue('smooth')
        onClick?.()
        await yieldOrContinue('smooth')
        openModal({
          id: ModalName.SIGN_IN,
          props: {
            onSuccess: () => router.push('/saved-searches'),
            isRenterOnlyLogin: true,
          },
        })
      }

      await yieldOrContinue('interactive')
      window.eventTracker?.track('click', {
        section: 'menu',
        item: 'saved_searches',
      })
    },
    [isLoggedIn, openModal, router, onClick]
  )

  return (
    <Link
      prefetch={false}
      href="/saved-searches"
      className={styles.item}
      onClick={handleClick}
      data-tid="saved-searches"
      data-tag_action="ignore"
    >
      Saved Searches{' '}
      {isLoggedIn && (
        <span data-tid="saved-search-count" className={styles.count}>
          {savedSearchesCount}
        </span>
      )}
    </Link>
  )
}
