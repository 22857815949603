import {
  MAX_PRICE_PREFIX,
  MIN_PRICE_PREFIX,
} from '@brand/search/refinements.const'
import { numberWithCommas } from '@rentpath/web-utils'
import { MAX_DYNAMIC_PRICE, MIN_DYNAMIC_PRICE } from '../const/search.const'

/**
 * Creates a function that takes a route path and returns a RefinementMap reflecting the current refinements.
 * @param refinementMap
 */
export function createRefinementExtractor<T extends Record<string, unknown>>(
  refinementMap: T
) {
  const refinementSlugsByLength = Object.keys(refinementMap).sort(
    (a, b) => b.length - a.length
  )

  /**
   * Takes a route path and returns a RefinementMap reflecting the current refinements.
   * @param path
   */
  function extractRefinements(path: string, withDynamicPriceFilters = true): T {
    const currentRefinementMap: Record<string, unknown> = {}

    if (withDynamicPriceFilters) {
      const minPriceSlugMatch = path.match(
        new RegExp(`${MIN_PRICE_PREFIX}\\d+`)
      )
      const minPriceSlug = minPriceSlugMatch ? minPriceSlugMatch[0] : undefined
      const minPrice = minPriceSlug
        ? parseInt(minPriceSlug.replace(MIN_PRICE_PREFIX, ''))
        : undefined

      const maxPriceSlugMatch = path.match(
        new RegExp(`${MAX_PRICE_PREFIX}\\d+`)
      )
      const maxPriceSlug = maxPriceSlugMatch ? maxPriceSlugMatch[0] : undefined
      const maxPrice = maxPriceSlug
        ? parseInt(maxPriceSlug.replace(MAX_PRICE_PREFIX, ''))
        : undefined

      const isValidPrice = (price: number) =>
        price >= MIN_DYNAMIC_PRICE &&
        price <= MAX_DYNAMIC_PRICE &&
        Math.floor(price) === price

      if (minPriceSlug) {
        if (
          typeof minPrice === 'number' &&
          isValidPrice(minPrice) &&
          minPrice <= (maxPrice ?? Infinity)
        ) {
          currentRefinementMap[minPriceSlug] = {
            label: `$${numberWithCommas(minPrice)}`,
            value: minPrice,
            seoBucket: 'priceOver',
            seoLabel: null,
            seoSortKey: minPrice,
            noIndex: true,
            headlineLabel: `over $${numberWithCommas(minPrice)}`,
            filterKey: 'prices',
          }

          path = path.replace(minPriceSlug, '')
        }
      }

      if (maxPriceSlug) {
        if (
          typeof maxPrice === 'number' &&
          isValidPrice(maxPrice) &&
          maxPrice >= (minPrice ?? -Infinity)
        ) {
          currentRefinementMap[maxPriceSlug] = {
            label: `$${numberWithCommas(maxPrice)}`,
            value: maxPrice,
            seoBucket: 'priceUnder',
            seoLabel: null,
            seoSortKey: maxPrice,
            noIndex: false,
            headlineLabel: `under $${numberWithCommas(maxPrice)}`,
            filterKey: 'prices',
          }

          path = path.replace(maxPriceSlug, '')
        }
      }
    }

    for (let i = 0; i < refinementSlugsByLength.length; i++) {
      const slug = refinementSlugsByLength[i] + ''

      const matchedIndex = path.toLowerCase().indexOf(slug.toLowerCase())

      if (matchedIndex !== -1) {
        currentRefinementMap[slug] = refinementMap[slug]
        path = path.replace(slug, '')
      }
    }

    return currentRefinementMap as T
  }

  return extractRefinements
}
