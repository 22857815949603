import { Future, Result } from '@swan-io/boxed'

import type { GraphQLError } from 'graphql'

export type GraphqlRequesterOptions = {
  url: string
  brand: string
  clientName?: string
  clientVersion?: string
}

export class GraphqlRequester {
  constructor(private options: GraphqlRequesterOptions) {}

  request<Data, Vars>(
    query: string,
    variables?: Vars,
    headers?: RequestInit['headers']
  ) {
    return Future.make<
      Result<Data | (Data & { errors?: GraphQLError[] }), GraphQLError>
    >((resolve) => {
      const controller = new AbortController()

      const opts: RequestInit = {
        method: 'POST',
        signal: controller.signal,
        headers: {
          'content-type': 'application/json',
          'x-application-id': `${this.options.brand}/www`,
          'apollographql-client-name': this.options.clientName || 'unknown',
          'apollographql-client-version':
            this.options.clientVersion || 'unknown',
          ...headers,
        },
        body: JSON.stringify({ query, variables }),
      }

      fetch(this.options.url, opts)
        .then((res) => res.json())
        .then((json) => {
          // only return an Error if it is catastrophic.
          // (having no data is catastrophic)
          if (json.errors && !json.data) {
            return resolve(Result.Error(json.errors[0]))
          }

          // include errors if they exist to expose them to the client
          if (json.errors) {
            return resolve(Result.Ok({ ...json.data, errors: json.errors }))
          }

          return resolve(Result.Ok(json.data))
        })
        .catch((error) => resolve(Result.Error(error)))

      return () => controller.abort()
    })
  }
}
