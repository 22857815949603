'use client'

import Link from 'next/link'
import { userTotalSavedListings } from '../../user/user.store'
import styles from '@brand/slots/page-header/drawer-menu/drawer-menu.module.css'
import { useAtomValue } from 'jotai'

interface SavedSearchesLinkProps {
  onClick?: () => void
  text: string
}

export function SavedPropertiesLink(props: SavedSearchesLinkProps) {
  const { onClick, text } = props
  const savedPropertiesCount = useAtomValue(userTotalSavedListings)

  return (
    <Link
      prefetch={false}
      href="/saved"
      data-tag_item="my_rent"
      data-tid="saved-properties-menu"
      className={styles.item}
      onClick={onClick}
    >
      {text}{' '}
      {savedPropertiesCount > 0 && (
        <span data-tid="count" className={styles.count}>
          {savedPropertiesCount}
        </span>
      )}
    </Link>
  )
}
