/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  HTML: string;
  JSON: Record<string, unknown>;
  TourTimeString: string;
};

export type AdPartner = {
  meta?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
};

export type AerialViewVideoData = {
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

export type Aggregates = {
  bathText?: Maybe<Scalars['String']>;
  baths: AggregatesLowHigh;
  bedCountData?: Maybe<Array<Maybe<BedData>>>;
  bedText?: Maybe<Scalars['String']>;
  beds: AggregatesLowHigh;
  listingId: Scalars['String'];
  name: Scalars['String'];
  priceText?: Maybe<Scalars['String']>;
  prices: AggregatesLowHigh;
  sqFtRange?: Maybe<SquareFeetRange>;
  totalAvailable?: Maybe<Scalars['Int']>;
  unitsAvailableText?: Maybe<Scalars['String']>;
};

export type AggregatesLowHigh = {
  high?: Maybe<Scalars['Int']>;
  low?: Maybe<Scalars['Int']>;
};

/** Amenities */
export const enum Amenity {
  AcceptsCreditCardPayments = 'ACCEPTS_CREDIT_CARD_PAYMENTS',
  AcceptsElectronicPayments = 'ACCEPTS_ELECTRONIC_PAYMENTS',
  AirConditioning = 'AIR_CONDITIONING',
  Alarm = 'ALARM',
  AssistedLiving = 'ASSISTED_LIVING',
  AttFiber = 'ATT_FIBER',
  AvailableNow = 'AVAILABLE_NOW',
  Balcony = 'BALCONY',
  BasketballCourts = 'BASKETBALL_COURTS',
  BusinessCenter = 'BUSINESS_CENTER',
  CableReady = 'CABLE_READY',
  CampusShuttle = 'CAMPUS_SHUTTLE',
  Carport = 'CARPORT',
  CeilingFan = 'CEILING_FAN',
  College = 'COLLEGE',
  ConferenceRoom = 'CONFERENCE_ROOM',
  ControlledAccess = 'CONTROLLED_ACCESS',
  CorporateBillingAvailable = 'CORPORATE_BILLING_AVAILABLE',
  Covered = 'COVERED',
  CoveredParking = 'COVERED_PARKING',
  Deck = 'DECK',
  DisabilityAccess = 'DISABILITY_ACCESS',
  Dishwasher = 'DISHWASHER',
  DoorAttendant = 'DOOR_ATTENDANT',
  Elevator = 'ELEVATOR',
  EmergencyMaintenance = 'EMERGENCY_MAINTENANCE',
  EvCharging = 'EV_CHARGING',
  ExtraStorage = 'EXTRA_STORAGE',
  Fireplace = 'FIREPLACE',
  FitnessCenter = 'FITNESS_CENTER',
  FullConciergeService = 'FULL_CONCIERGE_SERVICE',
  FurnishedAvailable = 'FURNISHED_AVAILABLE',
  Garage = 'GARAGE',
  GarbageDisposal = 'GARBAGE_DISPOSAL',
  GardenTub = 'GARDEN_TUB',
  GasRange = 'GAS_RANGE',
  GatedAccess = 'GATED_ACCESS',
  Golf = 'GOLF',
  GreenCommunity = 'GREEN_COMMUNITY',
  HardwoodFloors = 'HARDWOOD_FLOORS',
  HighlyRated = 'HIGHLY_RATED',
  HighSpeedInternetAccess = 'HIGH_SPEED_INTERNET_ACCESS',
  HotTub = 'HOT_TUB',
  HousekeepingAvailable = 'HOUSEKEEPING_AVAILABLE',
  IncomeRestricted = 'INCOME_RESTRICTED',
  IndependentLiving = 'INDEPENDENT_LIVING',
  IndividualLeases = 'INDIVIDUAL_LEASES',
  Internet = 'INTERNET',
  InternetIncluded = 'INTERNET_INCLUDED',
  IslandKitchens = 'ISLAND_KITCHENS',
  LaundryFacility = 'LAUNDRY_FACILITY',
  LoftLayout = 'LOFT_LAYOUT',
  MediaCenter = 'MEDIA_CENTER',
  Microwave = 'MICROWAVE',
  Military = 'MILITARY',
  NewConstruction = 'NEW_CONSTRUCTION',
  NewRenovatedInterior = 'NEW_RENOVATED_INTERIOR',
  NoCreditCheck = 'NO_CREDIT_CHECK',
  OnsitePersonnel = 'ONSITE_PERSONNEL',
  OnSiteMaintenance = 'ON_SITE_MAINTENANCE',
  OnSiteManagement = 'ON_SITE_MANAGEMENT',
  OnSitePatrol = 'ON_SITE_PATROL',
  OversizedClosets = 'OVERSIZED_CLOSETS',
  ParkingAvailable = 'PARKING_AVAILABLE',
  Patio = 'PATIO',
  Pets = 'PETS',
  PetFriendly = 'PET_FRIENDLY',
  PetPark = 'PET_PARK',
  Playground = 'PLAYGROUND',
  Pool = 'POOL',
  PublicTransportation = 'PUBLIC_TRANSPORTATION',
  RacquetballCourt = 'RACQUETBALL_COURT',
  RecentlyUpdated = 'RECENTLY_UPDATED',
  RecreationRoom = 'RECREATION_ROOM',
  Refrigerator = 'REFRIGERATOR',
  Remodeled = 'REMODELED',
  SeniorLiving = 'SENIOR_LIVING',
  SeHablaEspanol = 'SE_HABLA_ESPANOL',
  ShortTermAvailable = 'SHORT_TERM_AVAILABLE',
  SmokeFree = 'SMOKE_FREE',
  StainlessSteelAppliances = 'STAINLESS_STEEL_APPLIANCES',
  SubletsAllowed = 'SUBLETS_ALLOWED',
  Subsidies = 'SUBSIDIES',
  TennisCourt = 'TENNIS_COURT',
  TrailBikeHikeJog = 'TRAIL_BIKE_HIKE_JOG',
  UniversityShuttleService = 'UNIVERSITY_SHUTTLE_SERVICE',
  UtilitiesIncluded = 'UTILITIES_INCLUDED',
  VaultedCeilings = 'VAULTED_CEILINGS',
  View = 'VIEW',
  VirtualTours = 'VIRTUAL_TOURS',
  WalkInClosets = 'WALK_IN_CLOSETS',
  WasherDryerConnections = 'WASHER_DRYER_CONNECTIONS',
  WasherDryerInUnit = 'WASHER_DRYER_IN_UNIT',
  Waterfront = 'WATERFRONT',
  WirelessInternetAccess = 'WIRELESS_INTERNET_ACCESS'
};

export type AmenityFeeInfo = {
  fee?: Maybe<Scalars['Float']>;
  /** "No Amenity Fee", "No Info", "Monthly", "Yearly" */
  feeType?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

/** New amenity type to which has name and categories which it belongs. */
export type AmenityWithGroup = {
  category: Scalars['String'];
  highlight: Scalars['Boolean'];
  name: Scalars['String'];
};

/** The PMC's preferred photo assets (photoIDs) */
export type AssetsType = {
  aboutPhotoId?: Maybe<Scalars['String']>;
  amenitiesPhotoId?: Maybe<Scalars['String']>;
  bikingPhotoId: Scalars['String'];
  /** @deprecated Use transitPhotoId, walkingPhotoId or bikingPhotoId instead */
  gettingAroundPhotoId?: Maybe<Scalars['String']>;
  homePhotoId?: Maybe<Scalars['String']>;
  transitPhotoId: Scalars['String'];
  walkingPhotoId: Scalars['String'];
};

export type AttributionInput = {
  brand?: InputMaybe<Scalars['String']>;
  campaign?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['String']>;
  device?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  fbc?: InputMaybe<Scalars['String']>;
  fbclid?: InputMaybe<Scalars['String']>;
  fbp?: InputMaybe<Scalars['String']>;
  firebaseAppId?: InputMaybe<Scalars['String']>;
  gaAppInstanceId?: InputMaybe<Scalars['String']>;
  gaClientId?: InputMaybe<Scalars['String']>;
  gaSessionId?: InputMaybe<Scalars['String']>;
  gclid?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  msclkid?: InputMaybe<Scalars['String']>;
  pageType?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
  traffic?: InputMaybe<Scalars['String']>;
  utmCampaign?: InputMaybe<Scalars['String']>;
  utmMedium?: InputMaybe<Scalars['String']>;
  utmSource?: InputMaybe<Scalars['String']>;
  utmTerm?: InputMaybe<Scalars['String']>;
  visitId?: InputMaybe<Scalars['String']>;
};

export const enum AvailabilityStatus {
  AvailableWithoutCount = 'AVAILABLE_WITHOUT_COUNT',
  AvailableWithCount = 'AVAILABLE_WITH_COUNT',
  Inactive = 'INACTIVE',
  UnavailableWithoutFutureMoveDate = 'UNAVAILABLE_WITHOUT_FUTURE_MOVE_DATE',
  UnavailableWithFutureMoveDate = 'UNAVAILABLE_WITH_FUTURE_MOVE_DATE',
  Unknown = 'UNKNOWN'
};

/** Bath options to filter listings by. */
export const enum BathFilter {
  OneBathroom = 'ONE_BATHROOM',
  ThreeOrMoreBathrooms = 'THREE_OR_MORE_BATHROOMS',
  TwoBathroom = 'TWO_BATHROOM'
};

export type BathPreferences = {
  one?: InputMaybe<Scalars['Int']>;
  three?: InputMaybe<Scalars['Int']>;
  two?: InputMaybe<Scalars['Int']>;
};

export type BedData = {
  beds?: Maybe<Scalars['Int']>;
  prices?: Maybe<AggregatesLowHigh>;
  squareFeet?: Maybe<AggregatesLowHigh>;
};

/** Bed options to filter listings by. */
export const enum BedFilter {
  FourOrMoreBedrooms = 'FOUR_OR_MORE_BEDROOMS',
  OneBedroom = 'ONE_BEDROOM',
  Studio = 'STUDIO',
  ThreeBedroom = 'THREE_BEDROOM',
  TwoBedroom = 'TWO_BEDROOM'
};

export type BedPreferences = {
  four?: InputMaybe<Scalars['Int']>;
  one?: InputMaybe<Scalars['Int']>;
  studio?: InputMaybe<Scalars['Int']>;
  three?: InputMaybe<Scalars['Int']>;
  two?: InputMaybe<Scalars['Int']>;
};

export type Bedrooms = {
  bed1?: Maybe<Scalars['Int']>;
  bed2?: Maybe<Scalars['Int']>;
  bed3?: Maybe<Scalars['Int']>;
  bed4?: Maybe<Scalars['Int']>;
  bed5?: Maybe<Scalars['Int']>;
  bed6?: Maybe<Scalars['Int']>;
  studio?: Maybe<Scalars['Int']>;
};

/** The Bike Score */
export type BikeType = {
  description?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
};

export type BotWithId = {
  id: Scalars['String'];
};

/** SEO POI links by category */
export type CategorizedPois = {
  /** Airport-type POI links */
  airports: Array<SeoLink>;
  /** amusement-park-type POI links */
  amusementParks: Array<SeoLink>;
  /** aquariums-type POI links */
  aquariums: Array<SeoLink>;
  /** casino-type POI links */
  casinos: Array<SeoLink>;
  /** garden-type POI links */
  gardens: Array<SeoLink>;
  /** hospital-type POI links */
  hospitals: Array<SeoLink>;
  /** every other type not explicitly requested ends up here */
  other: Array<SeoLink>;
  /** park-type POI links */
  parks: Array<SeoLink>;
  /** public-transit-type POI links */
  publicTransit: Array<SeoLink>;
  /** shopping-mall-type POI links */
  shoppingMalls: Array<SeoLink>;
  /** stadium-type POI links */
  stadiums: Array<SeoLink>;
  /** street-type POI links */
  streets: Array<SeoLink>;
  /** zoo-type POI links */
  zoos: Array<SeoLink>;
};


/** SEO POI links by category */
export type CategorizedPoisAirportsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** SEO POI links by category */
export type CategorizedPoisAmusementParksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** SEO POI links by category */
export type CategorizedPoisAquariumsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** SEO POI links by category */
export type CategorizedPoisCasinosArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** SEO POI links by category */
export type CategorizedPoisGardensArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** SEO POI links by category */
export type CategorizedPoisHospitalsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** SEO POI links by category */
export type CategorizedPoisOtherArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** SEO POI links by category */
export type CategorizedPoisParksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** SEO POI links by category */
export type CategorizedPoisPublicTransitArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** SEO POI links by category */
export type CategorizedPoisShoppingMallsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** SEO POI links by category */
export type CategorizedPoisStadiumsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** SEO POI links by category */
export type CategorizedPoisStreetsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** SEO POI links by category */
export type CategorizedPoisZoosArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type CategoryRating = {
  averageRating: Scalars['Float'];
  fiveStarCount: Scalars['Int'];
  fourStarCount: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  oneStarCount: Scalars['Int'];
  threeStarCount: Scalars['Int'];
  twoStarCount: Scalars['Int'];
};

/** A category score */
export type CategoryScore = {
  contribution?: Maybe<Scalars['Float']>;
  maxContribution?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Int']>;
};

/** Various category scores */
export type CategoryScoresType = {
  culture?: Maybe<CategoryScore>;
  dineDrink?: Maybe<CategoryScore>;
  errands?: Maybe<CategoryScore>;
  grocery?: Maybe<CategoryScore>;
  parks?: Maybe<CategoryScore>;
  schools?: Maybe<CategoryScore>;
  shopping?: Maybe<CategoryScore>;
};

/** Configurations for the various chatbots a PMC might use */
export type ChatbotsType = {
  betterbot?: Maybe<BotWithId>;
  hylybot?: Maybe<BotWithId>;
};

export type CitiesByStateInput = {
  /** total requested from elastic */
  limit?: InputMaybe<Scalars['Int']>;
  minListings?: InputMaybe<Scalars['Int']>;
  minPriorityScore?: InputMaybe<Scalars['Float']>;
  orMinSeoVisits?: InputMaybe<Scalars['Int']>;
  /** when `randomizedSubset` is greater than 0, the total returned from elastic is shuffled and then the first X number of seolinks are returned (where X is randomizedSubset) */
  randomizedSubset?: InputMaybe<Scalars['Int']>;
};

export const enum CityClassification {
  HighInventory = 'HIGH_INVENTORY',
  LowInventory = 'LOW_INVENTORY',
  Metro = 'METRO',
  NoInventory = 'NO_INVENTORY'
};

/** A city with at least one listing */
export type CityWithListings = {
  /** The city name suitable for display */
  displayName?: Maybe<Scalars['String']>;
  /** The count of listings in the city */
  listingCount?: Maybe<Scalars['Int']>;
  /** The city name suitable for use in a URI */
  slug?: Maybe<Scalars['String']>;
};

export type CityWithListingsInput = {
  minListings?: InputMaybe<Scalars['Int']>;
};

export type CostOfLiving = {
  food: CostOfLivingDetails;
  healthcare: CostOfLivingDetails;
  housing: CostOfLivingDetails;
  transportation: CostOfLivingDetails;
  utilities: CostOfLivingDetails;
};

export type CostOfLivingDetails = {
  /**
   * The ratio of the neighborhood's category cost compared to the rest of the country
   * Examples:
   *   1.3 - means that the category is 30% higher than the  national average
   *   0.45 - means that the category is 55% lower than the  national average
   */
  nationalComparisonRatio: Scalars['Float'];
  /**
   * The ratio of the neighborhood's category cost compared to the rest of the state
   * Examples:
   *   1.3 - means that the category is 30% higher than the state average
   *   0.45 - means that the category is 55% lower than the state average
   */
  stateComparisonRatio: Scalars['Float'];
};

/** A county with at least one listing */
export type CountyWithListings = {
  /** The county name suitable for display */
  displayName?: Maybe<Scalars['String']>;
  /** The count of listings in the county */
  listingCount?: Maybe<Scalars['Int']>;
  /** The county name suitable for use in a URI */
  slug?: Maybe<Scalars['String']>;
};

export type CountyWithListingsInput = {
  /** Minimum number of listings required for a County to appear, default: 1 */
  minListings?: InputMaybe<Scalars['Int']>;
  /** Radius for a radial search around a point defined by lat, lng arguments, default: 10 */
  radiusInMiles?: InputMaybe<Scalars['Float']>;
  /** sorting, defaults to NAME_ASC */
  sort?: InputMaybe<CountyWithListingsSortType>;
};

export const enum CountyWithListingsSortType {
  /** Sort results on listing count descending */
  ListingCountDesc = 'LISTING_COUNT_DESC',
  /** Sort results on county name ascending, the default */
  NameAsc = 'NAME_ASC'
};

export type CurrentLocationNameInput = {
  city?: InputMaybe<Scalars['String']>;
  stateAbbr?: InputMaybe<Scalars['String']>;
};

export type Deal = {
  applyBy?: Maybe<Scalars['DateTime']>;
  applyPeriod?: Maybe<Scalars['String']>;
  cashOffDescription?: Maybe<Scalars['String']>;
  cashValue?: Maybe<Scalars['Int']>;
  couponCategory: Scalars['String'];
  description: Scalars['String'];
  feeType: Array<Scalars['String']>;
  freeWeeks?: Maybe<Scalars['Int']>;
  idx?: Maybe<Scalars['Int']>;
  leaseBy?: Maybe<Scalars['String']>;
  leaseTerm?: Maybe<Scalars['Int']>;
  unitType?: Maybe<Scalars['String']>;
};

export type DealType = {
  kind: DealTypeKind;
  text: Scalars['String'];
};

export const enum DealTypeKind {
  CashOff = 'CASH_OFF',
  FreeTime = 'FREE_TIME',
  LowerFees = 'LOWER_FEES'
};

export type Demographics = {
  city: DemographicsSections;
  hood: DemographicsSections;
};

export type DemographicsNormalized = {
  label: Scalars['String'];
  percent: Scalars['Int'];
};

export type DemographicsSections = {
  /** ageGrouped grouped by labels 0-10, 10-20, 20-45, 45-65, and 65+ */
  ageGrouped?: Maybe<Array<DemographicsNormalized>>;
  /** commuteGrouped grouped by labels 0-15 mins, 15-30 min, 30-40 min, 40-50 min, and 60+ mins */
  commuteGrouped?: Maybe<Array<DemographicsNormalized>>;
  /** educationGrouped grouped by labels high school, associate, bachelor, master, and doctorate */
  educationGrouped?: Maybe<Array<DemographicsNormalized>>;
  /** familySizeGrouped grouped by labels 2 people, 3 people, 4 people, 5 people, and 6+ people */
  familySizeGrouped?: Maybe<Array<DemographicsNormalized>>;
  /** householdEarningsGrouped grouped by labels 0-50k, 50-100k, 100-150k, 150-200k, and 200k+ */
  householdEarningsGrouped?: Maybe<Array<DemographicsNormalized>>;
};

export type DensityAreas = {
  national?: Maybe<DensityRating>;
  perCapita?: Maybe<DensityRating>;
  similarAreas?: Maybe<DensityRating>;
};

/** Density ratings for points of interest */
export const enum DensityRating {
  High = 'HIGH',
  Highest = 'HIGHEST',
  Low = 'LOW',
  Lowest = 'LOWEST',
  Normal = 'NORMAL'
};

export type DiamondMaxPhotos = {
  lg?: Maybe<Photo>;
  md?: Maybe<Photo>;
  sm?: Maybe<Photo>;
};

export type Dimensions = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** A map of a single floor's units. */
export type EngrainFloor = {
  id: Scalars['String'];
  label: Scalars['String'];
  sort: Scalars['Int'];
};

/** A map of the units of a property. */
export type EngrainUnitMap = {
  floors: Array<EngrainFloor>;
  mapId: Scalars['String'];
};

export type Fees = {
  adminFee?: Maybe<Scalars['String']>;
  amenity?: Maybe<AmenityFeeInfo>;
  applicationFee?: Maybe<Scalars['String']>;
  insurance?: Maybe<InsuranceFeeInfo>;
  salesTax?: Maybe<Scalars['Float']>;
  utilities?: Maybe<UtilitiesFeeInfo>;
};

export type FloorPlan = {
  availabilityStatusCode?: Maybe<AvailabilityStatus>;
  availabilityText?: Maybe<Scalars['String']>;
  availableCount?: Maybe<Scalars['Int']>;
  availableDate?: Maybe<Scalars['DateTime']>;
  bathCount?: Maybe<Scalars['Int']>;
  bathText?: Maybe<Scalars['String']>;
  bedCount?: Maybe<Scalars['Int']>;
  bedText?: Maybe<Scalars['String']>;
  bedsAndBathsText?: Maybe<Scalars['String']>;
  deposit?: Maybe<Scalars['Float']>;
  halfBathCount?: Maybe<Scalars['Int']>;
  halfBathText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image3dFurnished?: Maybe<Photo>;
  image3dUnfurnished?: Maybe<Photo>;
  imageFloorPlan?: Maybe<Photo>;
  name?: Maybe<Scalars['String']>;
  priceRange?: Maybe<Range>;
  priceRangeText?: Maybe<Scalars['String']>;
  /** Options include '/mo', '/wk', '/rm', '/day' */
  priceTerm?: Maybe<Scalars['String']>;
  /** @deprecated Use `sqFtRange` instead. */
  sqFt?: Maybe<Scalars['Int']>;
  sqFtRange?: Maybe<SquareFeetRange>;
  /** @deprecated Use `sqFtRange` instead. */
  sqFtText?: Maybe<Scalars['String']>;
  units: Array<FloorPlanUnit>;
};

export type FloorPlanUnit = {
  availabilityStatusCode?: Maybe<AvailabilityStatus>;
  dateAvailable?: Maybe<Scalars['DateTime']>;
  deposit?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  minSqft?: Maybe<Scalars['Int']>;
  rent?: Maybe<Scalars['String']>;
  unitFloor?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['ID']>;
};

export type FloorplanDrop = {
  /** The id is a combination of the endecaid and unitlayoutid */
  id: Scalars['String'];
  priceDrop: Scalars['Int'];
};

/** Specifies how to sort geo links, default: priorityScore descending */
export const enum GeoSort {
  /** Sort by a combination of priorityScore/distance */
  WeightedRank = 'WEIGHTED_RANK'
};

export type GooglePlace = {
  numRatings?: Maybe<Scalars['Int']>;
  placeId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
};

export type HdTour = {
  imageToken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  vendorThumbnailUrl?: Maybe<Scalars['String']>;
};

export type IncludedUtilitiesInfo = {
  notes?: Maybe<Scalars['String']>;
  utilities?: Maybe<Array<Scalars['String']>>;
};

export type IncomeRestriction = {
  /** Maximum annual income for this restriction. Guaranteed to be a price string if available. */
  maxAnnualIncome?: Maybe<Scalars['String']>;
  /**
   * Maximum number of occupants for this restriction. Typically a number, but not always.
   * Could be a range (e.g. 1-6) or anything else that the management company entered for this field.
   */
  maxOccupants?: Maybe<Scalars['String']>;
  /**
   * If the max annual income could not be parsed as a price string then this field is returned with the value.
   * This text is entered by the management company and could be anything.
   */
  rawText?: Maybe<Scalars['String']>;
};

export type InsuranceFeeInfo = {
  minLiability?: Maybe<Scalars['Int']>;
  minProperty?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  /** "Defer To Property", "Not Available", "Available", "No Info" */
  offeredByProperty?: Maybe<Scalars['String']>;
  /** "Defer To Property", "Not Required", "Required", "No Info" */
  requirementInfo?: Maybe<Scalars['String']>;
};

export type Interleaving = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  interval?: InputMaybe<Scalars['Int']>;
};

export type InternetService = {
  connectionTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  maxSpeedMbps?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
};

export type IpSrcInput = {
  iv: Scalars['String'];
  str: Scalars['String'];
};

export const enum LeadChannel {
  /** AG, Rent, Rentals - All Active listings (Default when not provided) */
  Active = 'ACTIVE',
  /** AG, Rent - Inactive listings */
  Inactive = 'INACTIVE',
  /** AG, Rent - Indicates a scheduled tour. This will inform downstream to process as such. */
  Tour = 'TOUR'
};

export const enum LeadDevice {
  /** Used for ads like Facebook and TikTok */
  Ad = 'AD',
  /** Native Android apps */
  Android = 'ANDROID',
  /** Desktop */
  Desktop = 'DESKTOP',
  /** Native iOS apps */
  Ios = 'IOS',
  /** Any mobile device through the browser */
  Mobile = 'MOBILE'
};

/** Data for lead event submitted to Facebook. Subset of properties from UserEventInput. */
export type LeadFacebookEventInput = {
  email?: InputMaybe<Scalars['String']>;
  /** Event transaction id - identifier for lead events */
  evTransId?: InputMaybe<Scalars['String']>;
  eventType: UserEventType;
  ipSrc: IpSrcInput;
  leadType?: InputMaybe<UserEventLeadType>;
  /** Id of the selected listing */
  listingId?: InputMaybe<Scalars['String']>;
  /** Revenue for the selected listing, when applicable */
  listingRevenue?: InputMaybe<Scalars['Float']>;
  /** Active test event code provided by the Facebook events console */
  testEventId?: InputMaybe<Scalars['String']>;
  /** Page url when the event occurred. */
  url: Scalars['String'];
  userAgent: Scalars['String'];
};

export const enum LeadLeaseTerm {
  /** 4-6 months */
  FourToSixMonths = 'FOUR_TO_SIX_MONTHS',
  /** 1-3 months */
  OneToThreeMonths = 'ONE_TO_THREE_MONTHS',
  /** 7-12 months */
  SevenToTwelveMonths = 'SEVEN_TO_TWELVE_MONTHS',
  /** 13+ months */
  ThirteenMonthsPlus = 'THIRTEEN_MONTHS_PLUS'
};

export const enum LeadOccupants {
  /** 4+ */
  FourPlus = 'FOUR_PLUS',
  /** 1 */
  One = 'ONE',
  /** 3 */
  Three = 'THREE',
  /** 2 */
  Two = 'TWO'
};

export const enum LeadPets {
  /** 0 */
  None = 'NONE',
  /** 1 */
  One = 'ONE',
  /** 3+ */
  ThreePlus = 'THREE_PLUS',
  /** 2 */
  Two = 'TWO'
};

export const enum LeadPreferredContact {
  Email = 'EMAIL',
  Phone = 'PHONE'
};

/** Optional questions for display on lead forms */
export type LeadQuestions = {
  /**
   * An array of ints representing an optional lead form question
   * 1 = Beds
   * 2 = Occupants
   * 3 = Pets
   * 4 = Lease Terms
   */
  ids?: Maybe<Array<Scalars['String']>>;
};

export const enum LeadSmsConsentEntity {
  Advertiser = 'advertiser',
  Ag = 'ag',
  Integrationpartner = 'integrationpartner',
  Rent = 'rent',
  Rentals = 'rentals'
};

export type LeadSubmissionInput = {
  /** Preference for filing lease application online. */
  applicationOptIn?: InputMaybe<Scalars['Boolean']>;
  baths?: InputMaybe<Scalars['Int']>;
  beds?: InputMaybe<Scalars['Int']>;
  /** Typically comes from a cookie of the same name. Max length 19 digits. */
  campaignId?: InputMaybe<Scalars['String']>;
  /**
   * Position on the page where the lead form was submitted.
   * Example: 'inline', 'modal', 'photo-modal'
   */
  context?: InputMaybe<Scalars['String']>;
  /**
   * Identifies the device. Must be one of the following:
   *      *   - AD - Used for ads like Facebook and TikTok
   *      *   - ANDROID - Native Android apps
   *      *   - IOS - Native iOS apps
   *      *   - MOBILE - Any mobile device through the browser
   *      *   - WEB - Desktop
   */
  device: LeadDevice;
  /**
   * Fires a number that corresponds to an SEM campaign defined in the querystring as ef_id.
   * This is a querystring value and is set when a user comes in by clicking on an ad in a search engine.
   * Typically comes from a cookie of the same name.
   */
  efId?: InputMaybe<Scalars['String']>;
  /** Required: email address - Limit: 255 */
  email: Scalars['String'];
  /**
   * AG uses autotagging_session_id + '_' + endeca_id. Related to adobe tracking pixel
   * When supplied, will check that email and email_confirmation values match. If the email_confirmation field is missing, this validation will be skipped.
   */
  emailConfirmation?: InputMaybe<Scalars['String']>;
  /** OpenSearch unique identifier for unit/floorplan (if unspecified, indicates interest at the listing level instead of a specific unit). */
  endecaId?: InputMaybe<Scalars['String']>;
  /** Related to adobe tracking pixel. */
  evTransId?: InputMaybe<Scalars['String']>;
  facebookData?: InputMaybe<LeadFacebookEventInput>;
  /** Optional: First Name - Limit: 50 You must supply either a "name", OR both "firstName" and "lastName" */
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  /** IPv4 or IPv6 IP address */
  ipAddress?: InputMaybe<Scalars['String']>;
  /**
   * Identifies the channel, defaults to ACTIVE (1)
   * Optional: Last Name - Limit: 50 You must supply either a "name", OR both "firstName" and "lastName"
   */
  lastName?: InputMaybe<Scalars['String']>;
  leadTypeId?: InputMaybe<LeadChannel>;
  listingId: Scalars['String'];
  /** Custom message sent to property. */
  message?: InputMaybe<Scalars['String']>;
  /** Moving date. Must be the current or a future date. Example: 2023-07-28 */
  moveDate: Scalars['DateTime'];
  /** Number of days until move. Used to compute moveDate. */
  moveDatePreference?: InputMaybe<Scalars['Int']>;
  optInBrochure?: InputMaybe<Scalars['Boolean']>;
  optInNewsletter?: InputMaybe<Scalars['Boolean']>;
  /** Punctuation and spaces are accepted but removed. The resulting phone number must be 10 digits. */
  phone?: InputMaybe<Scalars['String']>;
  preferredMethodOfContact?: InputMaybe<LeadPreferredContact>;
  /** A formatted string using the min and max price. (e.g. $1100-$1300) */
  priceRange?: InputMaybe<Scalars['String']>;
  questionBeds?: InputMaybe<Array<InputMaybe<BedFilter>>>;
  questionIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  questionLeaseTerms?: InputMaybe<LeadLeaseTerm>;
  questionOccupants?: InputMaybe<LeadOccupants>;
  questionPets?: InputMaybe<LeadPets>;
  /**
   * Filter refinements.
   * e.g. "2 Bedrooms", "2 Baths", "3 Stars & Above"
   */
  refinements?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** A GA session id used for tracking downstream. Use no_session_id_ + new Date().getTime() if not available. */
  sessionId?: InputMaybe<Scalars['String']>;
  /** Use all that apply. Creating a comma delimited list with the values should not exceed 100 characters. */
  smsConsentEntities?: InputMaybe<Array<InputMaybe<LeadSmsConsentEntity>>>;
  smsOptIn?: InputMaybe<Scalars['Boolean']>;
  /** GA hit id derived from window.hit_id */
  taggingHitId?: InputMaybe<Scalars['String']>;
  /** A timestamp used when scheduling or requesting a tour. Accepted formats include timestamps with an offset or a naive timestamp which would be interpretted as the local time for the property. Must be no older than 30 days in the past and 365 days in the future. */
  tourDateTime?: InputMaybe<Scalars['String']>;
  tourId?: InputMaybe<Scalars['String']>;
  /** The property ID used by the external tour vendor. */
  tourPropertyId?: InputMaybe<Scalars['String']>;
  tourProvider?: InputMaybe<Scalars['String']>;
  /** The requested or scheduled tour type. If a renter is asking to take a tour without specifying the type, use `unspecified`. */
  tourType?: InputMaybe<LeadTour>;
  tvisit?: InputMaybe<Scalars['String']>;
  website: LeadWebsite;
};

export const enum LeadTour {
  AgentGuided = 'AGENT_GUIDED',
  SelfGuided = 'SELF_GUIDED',
  Unspecified = 'UNSPECIFIED',
  VideoCall = 'VIDEO_CALL'
};

/** What type of lead it is. */
export const enum LeadTypeId {
  /** Active listings (Default when not provided) */
  ActiveUsers = 'ACTIVE_USERS',
  /** Inactive listings */
  InactiveUsers = 'INACTIVE_USERS',
  /** Indicates a scheduled tour. This will inform downstream to process as such. */
  Tour = 'TOUR'
};

export const enum LeadWebsite {
  AndroidAg = 'ANDROID_AG',
  AndroidRent = 'ANDROID_RENT',
  Apartmentguide = 'APARTMENTGUIDE',
  DmsLanding = 'DMS_LANDING',
  Facebookrentsae = 'FACEBOOKRENTSAE',
  IpadAg = 'IPAD_AG',
  IpadRent = 'IPAD_RENT',
  IphoneAg = 'IPHONE_AG',
  IphoneRent = 'IPHONE_RENT',
  Keylasso = 'KEYLASSO',
  LeademailAg = 'LEADEMAIL_AG',
  LeademailRent = 'LEADEMAIL_RENT',
  Microsite = 'MICROSITE',
  MApartmentguide = 'M_APARTMENTGUIDE',
  MRent = 'M_RENT',
  MRentals = 'M_RENTALS',
  Realtor = 'REALTOR',
  Redfin = 'REDFIN',
  Rent = 'RENT',
  Rentals = 'RENTALS',
  RentpathSearch = 'RENTPATH_SEARCH',
  RentpathSocial = 'RENTPATH_SOCIAL',
  Rentsites = 'RENTSITES',
  Uloop = 'ULOOP',
  Walkscore = 'WALKSCORE',
  Zumper = 'ZUMPER'
};

/** A web anchor/hyper-link. */
export type Link = {
  href: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
  title: Scalars['String'];
};

export type LinkInfoQueryInput = {
  /** The URL to query for (ex: /georgia/atlanta-apartments) */
  url?: InputMaybe<Scalars['String']>;
};

/** A group of links. */
export type LinkSection = {
  links?: Maybe<Array<Maybe<Link>>>;
  name?: Maybe<Scalars['String']>;
};

export type Listing = {
  /** RPL value for ML sort and tagging */
  NV_RPL?: Maybe<Scalars['Float']>;
  /** Listing ad partners. */
  adPartners: Array<AdPartner>;
  /** Listing ad target code. */
  adTargetCode?: Maybe<Scalars['String']>;
  /** Listing address. */
  address?: Maybe<Scalars['String']>;
  /** Combined listing address. */
  addressFull?: Maybe<Scalars['String']>;
  /** Listing google aerial id. */
  aerialId?: Maybe<Scalars['String']>;
  /** Listing amenities. */
  amenities: Array<Scalars['String']>;
  amenitiesAdditional: Array<Scalars['String']>;
  amenitiesApartment: Array<Scalars['String']>;
  amenitiesCommunity: Array<Scalars['String']>;
  /** Listing highlighted amenities. */
  amenitiesHighlighted: Array<Scalars['String']>;
  amenitiesSpecial: Array<Scalars['String']>;
  /** Listing amenities with categories */
  amenitiesWithGroups: Array<AmenityWithGroup>;
  /** PMC's online application URL */
  applicationUrl?: Maybe<Scalars['String']>;
  availabilityStatus: ListingAvailabilityStatus;
  /** @deprecated Use `leasingTerms` instead */
  availableLeaseTerms: Array<Maybe<Scalars['String']>>;
  /** @deprecated Use `leasingTerms` instead */
  availableLeaseTermsText?: Maybe<Scalars['String']>;
  bathRange?: Maybe<Range>;
  bathText?: Maybe<Scalars['String']>;
  bedCountData?: Maybe<Array<Maybe<BedData>>>;
  bedRange?: Maybe<Range>;
  bedText?: Maybe<Scalars['String']>;
  /** List of category badges */
  categoryBadges: Array<Scalars['String']>;
  /** Category ratings for the listing */
  categoryRatings: Array<CategoryRating>;
  /** The shapefile id(s) for the listing's city. */
  cityIds?: Maybe<Array<Scalars['String']>>;
  /** Deals parsed into more specific types */
  dealTypes?: Maybe<Array<DealType>>;
  /** Deals and coupons */
  deals: Array<Deal>;
  /** @deprecated Use couponCategory or description from `deals` instead */
  dealsText?: Maybe<Scalars['String']>;
  /** Listing description. */
  description?: Maybe<Scalars['String']>;
  /** The provided photos chosen to display for a Diamond Max Property */
  diamondMaxPhoto?: Maybe<DiamondMaxPhotos>;
  /** disclaimer */
  disclaimer?: Maybe<Scalars['String']>;
  dogsAllowed?: Maybe<Scalars['Boolean']>;
  earliestAvailability?: Maybe<Scalars['DateTime']>;
  /** Listing fees. */
  fees?: Maybe<Fees>;
  /** Listing floor plans. */
  floorPlans?: Maybe<Array<FloorPlan>>;
  /** @deprecated We are no longer using this beta service (contractually). */
  googlePlace?: Maybe<GooglePlace>;
  hasHdTour: Scalars['Boolean'];
  /** define whether or not the given listing has lead emails */
  hasLeadEmails: Scalars['Boolean'];
  hasPriceDrops: Scalars['Boolean'];
  hasVideosOrTours: Scalars['Boolean'];
  hdTours: Array<HdTour>;
  /** HoodIds of a listing */
  hoodIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Listing ID. */
  id: Scalars['ID'];
  incomeRestrictions?: Maybe<Array<IncomeRestriction>>;
  /** Internal ID, used for GA tracking */
  internalId?: Maybe<Scalars['String']>;
  /** internetServices */
  internetServices: Array<InternetService>;
  /** The listing is any source type other than INACTIVE. */
  isActive: Scalars['Boolean'];
  isActiveSitesCustomer: Scalars['Boolean'];
  /** The listing is one of INACTIVE, LNP (Large, Never Paid), or CORELOGIC source types. */
  isBasic: Scalars['Boolean'];
  isDiamondMax: Scalars['Boolean'];
  /**
   * Means the property pays to be a featured listing.
   * Doesn't necessarily mean it's the current chosen featured for the search, which is random within a market.
   * @deprecated Dependent on backend data source. Use `listingSearch.featuredId` instead.
   */
  isFeatured: Scalars['Boolean'];
  /** True if listing is promoted. */
  isPromoted: Scalars['Boolean'];
  /** True if single family home. */
  isSingleFamily: Scalars['Boolean'];
  /**
   * Means the property pays to be a spotlight listing.
   * Doesn't necessarily mean it's the current chosen spotlight for the search, which is random within a market.
   * @deprecated Dependent on backend data source. Use `listingSearch.spotlightId` instead.
   */
  isSpotlight: Scalars['Boolean'];
  /** The listing is either terminated or is not a MYADS or APTGUIDE source type. */
  isUnpaid: Scalars['Boolean'];
  leadPhoneRequired: Scalars['Boolean'];
  /** Optional questions for plainum/diamond listings used for lead collection */
  leadQuestions?: Maybe<LeadQuestions>;
  /** Listing leasing terms. */
  leasingTerms: Array<Scalars['String']>;
  /** Listing lifestyles. */
  lifestyles: Array<Scalars['String']>;
  /** listingTier value that is responsible for sorting */
  listingTier?: Maybe<Scalars['String']>;
  /** Listing location details. */
  location?: Maybe<Location>;
  /** Formatted price text for map markers */
  mapMarkerPriceText?: Maybe<Scalars['String']>;
  /** marginalRevenueDesktop value for ML sort algorithm */
  marginalRevenueDesktop?: Maybe<MarginalRevenue>;
  /** marginalRevenueMobile value for ML sort algorithm */
  marginalRevenueMobile?: Maybe<MarginalRevenue>;
  /** MITS (feed) phone number for unpaid listings */
  mitsPhone: ListingPhoneNumber;
  /** Listing MLS information (only pertinent to MLS feed listings). */
  mlsInfo?: Maybe<MlsInfo>;
  /** Listing name */
  name?: Maybe<Scalars['String']>;
  /** Nearby city links. */
  nearbyCityLinks?: Maybe<Array<Maybe<Link>>>;
  /** Nearby college links. */
  nearbyCollegeLinks?: Maybe<Array<Maybe<Link>>>;
  /** Nearby hood links. */
  nearbyHoodLinks?: Maybe<Array<Maybe<Link>>>;
  /** All nearby links. */
  nearbyLinks?: Maybe<Array<Maybe<LinkSection>>>;
  /** Nearby listing IDs. */
  nearbyListingIDs?: Maybe<Array<Scalars['String']>>;
  /** Nearby and recommended listings. */
  nearbyListings?: Maybe<Array<Listing>>;
  /** Nearby locales (Foursquare Places, or Points of Interest) */
  nearbyLocales?: Maybe<NearbyPlaces>;
  /** Nearby POI links. */
  nearbyPOILinks?: Maybe<Array<Maybe<Link>>>;
  /** defines whether or not the given listing is "on-market" or "off-market". While "off-market" listings can be inferred to be unavailable, this field SHOULD NOT be used to determine availability. Instead use availabilityStatus. */
  offMarket: Scalars['Boolean'];
  /** Listing office hours. */
  officeHours: Array<OfficeHour>;
  /**
   * Optimized listing photos
   * Returns the photos for this listing with an algorithmically chosen cover photo at the front
   * Important: A unique session identifier is required to query this field
   */
  optimizedPhotos: Array<OptimizedPhoto>;
  /** Listing parking details. */
  parking: Array<Parking>;
  /** PDP views count show how much unique users looked this listing */
  pdpViews?: Maybe<Scalars['Int']>;
  /** Listing pet policy details. */
  petPolicies: Array<PetPolicy>;
  /** Phone number for native apps. */
  phoneApps?: Maybe<Scalars['String']>;
  /** Phone number for native apps coming from organic channel. */
  phoneAppsOrganic?: Maybe<Scalars['String']>;
  /** Formatted phone number for native apps coming from organic channel. */
  phoneAppsOrganicText?: Maybe<Scalars['String']>;
  /** Phone number for native apps coming from push/email channel. */
  phoneAppsPush?: Maybe<Scalars['String']>;
  /** Formatted phone number for native apps coming from push channel. */
  phoneAppsPushText?: Maybe<Scalars['String']>;
  /** Phone number for native apps coming from SEM channel. */
  phoneAppsSem?: Maybe<Scalars['String']>;
  /** Formatted phone number for native apps coming from SEM channel. */
  phoneAppsSemText?: Maybe<Scalars['String']>;
  /** Formatted phone number for native apps. */
  phoneAppsText?: Maybe<Scalars['String']>;
  /** Phone number for desktop web. */
  phoneDesktop?: Maybe<Scalars['String']>;
  /** Phone number for desktop web SEM (Search Engine Marketing). */
  phoneDesktopSem?: Maybe<Scalars['String']>;
  /** Formatted phone number for desktop web SEM (Search Engine Marketing) */
  phoneDesktopSemText?: Maybe<Scalars['String']>;
  /** Formatted phone number for desktop web. */
  phoneDesktopText?: Maybe<Scalars['String']>;
  /** Phone number for mobile web. */
  phoneMobile?: Maybe<Scalars['String']>;
  /** Phone number for mobile web SEM (Search Engine Marketing). */
  phoneMobileSem?: Maybe<Scalars['String']>;
  /** Formatted phone number for mobile web SEM (Search Engine Marketing). */
  phoneMobileSemText?: Maybe<Scalars['String']>;
  /** Formatted phone number for mobile web. */
  phoneMobileText?: Maybe<Scalars['String']>;
  /** PropertyWebsites-specific raw callrail phone number. */
  phoneSites?: Maybe<Scalars['String']>;
  /** PropertyWebsites-specific formatted callrail phone number. */
  phoneSitesText?: Maybe<Scalars['String']>;
  /** Listing photos. */
  photos?: Maybe<Array<Maybe<Photo>>>;
  /** Nearby listings under the same property management company. */
  portfolioProperties?: Maybe<Array<Listing>>;
  /** Listing price. */
  price?: Maybe<Scalars['Int']>;
  priceDrops: PriceDrops;
  priceRange?: Maybe<Range>;
  /** Listing price display text. */
  priceText?: Maybe<Scalars['String']>;
  /** @deprecated A listing can be both featured and promoted and is contextual based on search results */
  promotedText?: Maybe<Scalars['String']>;
  /** Listing property logo ID. */
  propertyLogo?: Maybe<Scalars['String']>;
  /** Listing property management company. */
  propertyManagementCompany?: Maybe<PropertyManagementCompany>;
  /** Property Maps feature (provided by Engrain/Unit Map) */
  propertyMap?: Maybe<EngrainUnitMap>;
  /** Listing property type. */
  propertyType?: Maybe<PropertyType>;
  /** Listing price range provided by the PMC */
  providedPriceRange?: Maybe<Range>;
  /** Public transit */
  publicTransit?: Maybe<PublicTransit>;
  /** Number of ratings. */
  ratingCount?: Maybe<Scalars['Int']>;
  /** Rating percentage from 0 - 100. */
  ratingPercent?: Maybe<Scalars['Float']>;
  /**
   * Ratings (stars) for listing. Sorted by star rating descending (i.e. 5, 4, 3, 2, 1).
   * If any ratings are available, then this array is guaranteed to have a length of 5.
   */
  ratings?: Maybe<Array<Rating>>;
  /** Recommended listing ids. */
  recommendedListingIDs?: Maybe<Array<Scalars['String']>>;
  /**
   * Redfin link for listing.
   * @deprecated Use `redfinLinks` instead
   */
  redfinLink?: Maybe<Link>;
  /** Redfin links for the listing's location. */
  redfinLinks?: Maybe<RedfinLinks>;
  /** Whether or not the property wants request tours enabled */
  requestTourOptOut: Scalars['Boolean'];
  /** Listing revenue for tagging */
  revenue?: Maybe<Scalars['Float']>;
  /** Reviews for listing. */
  reviews?: Maybe<Array<Review>>;
  roomForRent?: Maybe<Scalars['Boolean']>;
  schoolIds: Array<Scalars['String']>;
  /** Schools for listing. */
  schools?: Maybe<Array<School>>;
  searchCityStates?: Maybe<Array<Scalars['String']>>;
  /** seoLinks based on listing location */
  seoLinks?: Maybe<SeoLinks>;
  /** Combination of nearby and recommended listings. */
  similar?: Maybe<Array<Listing>>;
  /** Social Media links for listing. */
  socialLinks?: Maybe<SocialLinks>;
  sortA?: Maybe<Scalars['Float']>;
  sortBDesktop?: Maybe<Scalars['Float']>;
  sortBMobile?: Maybe<Scalars['Float']>;
  /** Listing ID from the source provider (e.g. Listhub) */
  sourceId?: Maybe<Scalars['String']>;
  specialTerms?: Maybe<Scalars['HTML']>;
  squareFeetRange?: Maybe<Range>;
  squareFeetText?: Maybe<Scalars['String']>;
  /** Phone contact information for a listing */
  telephony: ListingTelephony;
  /** Third Party Reviews for listing. */
  thirdPartyReviews?: Maybe<ThirdPartyReview>;
  /** The time zone id associated with the listing (e.g. "America/New_York") */
  timeZoneId?: Maybe<Scalars['String']>;
  /** Total units available for a listing */
  totalUnits: Scalars['Int'];
  /** @deprecated Use `tourProviderDetails.name` instead */
  tourProvider?: Maybe<Scalars['String']>;
  tourProviderDetails?: Maybe<TourProviderDetails>;
  /**
   * Times that are available for touring a given property in-person
   * `type` argument is optional and will default to IN_PERSON
   */
  tourTimes: Array<TourTime>;
  /** The listing source type. */
  tplsource?: Maybe<Scalars['String']>;
  /** Highlighted amenities, generated via LLM (e.g. OpenAI) */
  uniqueHighlights: Array<Scalars['String']>;
  /** Number of units available */
  unitsAvailable?: Maybe<Scalars['Int']>;
  /** Available unit display text */
  unitsAvailableText?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Listing URL Path */
  urlPathname?: Maybe<Scalars['String']>;
  utilityEstimates?: Maybe<UtilityEstimates>;
  /** Verified */
  verified: Scalars['Boolean'];
  /** Video Tour */
  videos: Array<Video>;
  /** Walkscore */
  walkScore?: Maybe<WalkScore>;
  /** web target number */
  webTargetNumber: ListingPhoneNumber;
  /** property website */
  website?: Maybe<Scalars['String']>;
  /** zScore */
  zScore?: Maybe<Scalars['Float']>;
  /** zipCode of listing location */
  zipCode?: Maybe<Scalars['String']>;
};


export type ListingHdToursArgs = {
  vendorThumbnailSize?: InputMaybe<Dimensions>;
};


export type ListingNearbyListingsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type ListingNearbyLocalesArgs = {
  perCategoryLimit?: InputMaybe<Scalars['Int']>;
  radiusInMiles?: InputMaybe<Scalars['Int']>;
  removeDuplicatesByName?: InputMaybe<Scalars['Boolean']>;
};


export type ListingOptimizedPhotosArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sessionId: Scalars['String'];
};


export type ListingPhotosArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type ListingPortfolioPropertiesArgs = {
  limit: Scalars['Int'];
};


export type ListingReviewsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  userSort?: InputMaybe<Array<Sort>>;
};


export type ListingSchoolsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type ListingSimilarArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  recommendationsVersion?: InputMaybe<Scalars['Int']>;
};


export type ListingTelephonyArgs = {
  input?: InputMaybe<AttributionInput>;
};


export type ListingTourTimesArgs = {
  dates: Array<Scalars['DateTime']>;
  type?: InputMaybe<TourType>;
};


export type ListingUtilityEstimatesArgs = {
  filters?: InputMaybe<UtilityEstimateListingFilters>;
};

export const enum ListingAvailabilityStatus {
  Future = 'FUTURE',
  Limited = 'LIMITED',
  Today = 'TODAY',
  Unavailable = 'UNAVAILABLE',
  Unknown = 'UNKNOWN'
};

export type ListingCount = {
  count?: Maybe<Scalars['Float']>;
  key?: Maybe<Scalars['String']>;
};

export type ListingCountBucket = {
  /** An array of new and old listing counts for a city */
  buckets: Array<Maybe<ListingCount>>;
};

export type ListingCountInput = {
  updatedWithinLastDays: Scalars['Float'];
};

export type ListingPhoneNumber = {
  formatted?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['String']>;
};

export type ListingReviewInput = {
  campaignId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  listingId: Scalars['String'];
  ratingsLocation?: InputMaybe<Scalars['Float']>;
  ratingsOverall?: InputMaybe<Scalars['Float']>;
  reasons: Scalars['String'];
  renterType?: InputMaybe<Scalars['String']>;
  review?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

/** Search Filters */
export type ListingSearchFiltersInput = {
  amenities?: InputMaybe<Array<Amenity>>;
  bath?: InputMaybe<BathFilter>;
  /** Allows multi bath search */
  baths?: InputMaybe<Array<BathFilter>>;
  bed?: InputMaybe<BedFilter>;
  /** Allows multi bed search */
  beds?: InputMaybe<Array<BedFilter>>;
  /**
   * Exclude certain cities.
   * Expected format: "Yakutat, AK"
   */
  excludeCityState?: InputMaybe<Scalars['String']>;
  excludeListingIds?: InputMaybe<Array<Scalars['String']>>;
  interleaving?: InputMaybe<Interleaving>;
  /** When listingId supplied to ListingSearchFiltersInput, results filtered on listingId. */
  listingId?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<LocationInput>;
  /**
   * When specified, includes listings with availability up to and including the specified date.
   * Expected format: yyyy-MM-dd (e.g. "2023-07-04")
   */
  moveInDate?: InputMaybe<Scalars['String']>;
  petPolicies?: InputMaybe<PetPolicyFilter>;
  prices?: InputMaybe<RangeInput>;
  propertyTypes?: InputMaybe<Array<PropertyTypeFilter>>;
  queryText?: InputMaybe<Scalars['String']>;
  radius?: InputMaybe<Scalars['Int']>;
  squareFeet?: InputMaybe<RangeInput>;
  updatedWithinLastDays?: InputMaybe<Scalars['Int']>;
  withDeals?: InputMaybe<Scalars['Boolean']>;
  withRoomsForRent?: InputMaybe<Scalars['Boolean']>;
  withScheduleTour?: InputMaybe<Scalars['Boolean']>;
};

export type ListingSearchPricePercentiles = {
  p25?: Maybe<Scalars['Int']>;
  p50?: Maybe<Scalars['Int']>;
  p75?: Maybe<Scalars['Int']>;
};

export type ListingSearchResult = {
  /** Total available units that match all input filters and are currently available now or in the future */
  currentFutureAvailUnitTotal?: Maybe<Scalars['Int']>;
  /** The listing ID of the spotlight lising, only present if `withFeaturedListing` is true and the market has at least one featured listing */
  featuredId?: Maybe<Scalars['String']>;
  filterMatchResults: Array<Maybe<Aggregates>>;
  /**
   * Total available units that match all input filters and are currently available now or in the future
   * @deprecated Use `currentFutureAvailUnitTotal` instead.
   */
  hardMatchCurrentFutureAvailUnitTotal?: Maybe<Scalars['Int']>;
  /**
   * Total listings that match all input filters.
   * @deprecated Use `total` instead. Soft filtering is no longer supported, so we've retired the concept of a `hard match` total.
   */
  hardMatchTotal: Scalars['Int'];
  /**
   * Total available units that match all input filters
   * @deprecated Use `totalAvailableUnits` instead.
   */
  hardMatchTotalAvailableUnits?: Maybe<Scalars['Int']>;
  /** Collection of listings. */
  listings: Array<Listing>;
  /**
   * Metadata about search results
   * @deprecated Server side soft filtering is no longer supported.
   */
  meta?: Maybe<SearchIoMeta>;
  /**
   * Set to true if the backend service is fetching only the necessary fields for the given query or false if entire listing data is fetched per listing.
   * @deprecated This field optimization now happens automatically for every listing query, and this field is unnecessary.
   */
  optimizedServiceFields?: Maybe<Scalars['Boolean']>;
  /**
   * The value at the specified percentile of `listingpricelow` across all listings in the response, even ones on other pages.
   * Note: values are approximate and will vary with each query.
   */
  pricePercentiles?: Maybe<ListingSearchPricePercentiles>;
  /**
   * Collection of soft matched listings. These are listings thad don't fully match the search criteria's amenities.
   * @deprecated Server side soft filtering is no longer supported.
   */
  softMatches: Array<Listing>;
  /** The listing ID of the spotlight lising, only present if `withSpotlightListing` is true and the market has at least one spotlight listing */
  spotlightId?: Maybe<Scalars['String']>;
  /** Total listings found. */
  total: Scalars['Int'];
  /** Total available units that match all input filters */
  totalAvailableUnits?: Maybe<Scalars['Int']>;
};

/** Listing sort options */
export const enum ListingSort {
  DistanceAsc = 'DISTANCE_ASC',
  PriceHighToLow = 'PRICE_HIGH_TO_LOW',
  PriceLowToHigh = 'PRICE_LOW_TO_HIGH',
  Random = 'RANDOM',
  RatingHighToLow = 'RATING_HIGH_TO_LOW',
  ScheduleTourEnabled = 'SCHEDULE_TOUR_ENABLED'
};

export type ListingTelephony = {
  appNumber: ListingPhoneNumber;
  isSwapPhoneEligible: Scalars['Boolean'];
  marketingNumber: ListingPhoneNumber;
  mobileSemNumber: ListingPhoneNumber;
  mobileWebNumber: ListingPhoneNumber;
  originNumber: ListingPhoneNumber;
  semNumber: ListingPhoneNumber;
  sitesNumber: ListingPhoneNumber;
  webNumber: ListingPhoneNumber;
};


export type ListingTelephonyMarketingNumberArgs = {
  useDynamicNumber?: InputMaybe<Scalars['Boolean']>;
};

/** Location details. */
export type Location = {
  /** Bounding box of a location search */
  bbox?: Maybe<Array<Scalars['Float']>>;
  /** Cities within a state that have at least "minListings" listings */
  citiesWithListings: Array<CityWithListings>;
  city?: Maybe<Scalars['String']>;
  /** A classification of the density of inventory within the city. */
  cityClassification?: Maybe<CityClassification>;
  citySlug?: Maybe<Scalars['String']>;
  /** Counties with at least "minListing" listings, within either a given state, or a circle defined by lat, lng, radiusInMiles, or the intersection of both */
  countiesWithListings: Array<CountyWithListings>;
  /** County name */
  county?: Maybe<Scalars['String']>;
  /** County slug */
  countySlug?: Maybe<Scalars['String']>;
  /**
   * Diamond Max is a sales packages that boosts listings to the top of search results.
   * In addition, we show Diamond Max listings on the home page
   */
  diamondMax?: Maybe<Listing>;
  geoId?: Maybe<Scalars['String']>;
  /** Metasaurus search path (e.g., ZIP, MILITARY, COLLEGE, etc.) */
  geoType?: Maybe<LocationSearchTypes>;
  hasRentTrends?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use `neighborhoods` instead */
  hood?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  linkInfo?: Maybe<SeoLink>;
  listingCount?: Maybe<ListingCountBucket>;
  /** Search listings within a location. */
  listingSearch?: Maybe<ListingSearchResult>;
  lng?: Maybe<Scalars['Float']>;
  /** @deprecated use `reviewsByType` instead that has more accurate results */
  locationReviews?: Maybe<LocationReviewsByGroup>;
  marketCodes?: Maybe<MetasaurusSearchMarketCodes>;
  monthlyRentDistribution?: Maybe<RentDistribution>;
  name?: Maybe<Scalars['String']>;
  nearbyCityLinks?: Maybe<Array<Maybe<Link>>>;
  nearbyCollegeLinks?: Maybe<Array<Maybe<Link>>>;
  nearbyHoodLinks?: Maybe<Array<Maybe<Link>>>;
  nearbyLinks?: Maybe<Array<Maybe<LinkSection>>>;
  nearbyPOILinks?: Maybe<Array<Maybe<Link>>>;
  /**
   * Returns nearby places that are within the location's shapefile, which is inflated
   * to account for places that are nearby properties on the edge of the neighborhood.
   */
  nearbyPlaces?: Maybe<NearbyPlaces>;
  /** Only applies to neighborhood searches */
  neighborhoodCostOfLiving?: Maybe<CostOfLiving>;
  neighborhoods?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Average rent values per floorplan over time, normalized for display */
  normalizedOverallRent?: Maybe<NormalizedRentTrendsType>;
  /** Average rent values per floorplan over time */
  overallRent?: Maybe<RentTrendsType>;
  /**
   * Average rent values per floorplan sorted by month/year
   * These values have been interpolated and converted for display
   */
  overallRentByMonthAndYear?: Maybe<Scalars['JSON']>;
  poi?: Maybe<Scalars['String']>;
  poiSlug?: Maybe<Scalars['String']>;
  /** Radius distance in miles from the location */
  radiusInMiles?: Maybe<Scalars['Int']>;
  /** @deprecated Use `redfinLinks` instead */
  redfinLink?: Maybe<Link>;
  redfinLinks?: Maybe<RedfinLinks>;
  rentPercentiles?: Maybe<RentPercentiles>;
  reviewsByType?: Maybe<LocationReviewsByType>;
  school?: Maybe<Scalars['String']>;
  schoolDistrict?: Maybe<Scalars['String']>;
  schoolDistrictSlug?: Maybe<Scalars['String']>;
  schoolSlug?: Maybe<Scalars['String']>;
  schools?: Maybe<Array<School>>;
  /** @deprecated The field used to derive this data is no longer provided by the vendor. */
  schoolsByEducationLevel?: Maybe<SchoolsByEducationLevel>;
  /** returns scored SEO links meeting (minimum score and minimum listings) OR minimum seo visit thresholds */
  seoLinks?: Maybe<SeoLinks>;
  seoMetadata?: Maybe<SeoMetadata>;
  seoPath?: Maybe<Scalars['String']>;
  shapeFile?: Maybe<Scalars['JSON']>;
  /** The id of the associated shapefile. (Note - these are not unique across location types.) */
  shapeId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateAbbr?: Maybe<Scalars['String']>;
  stateSlug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  urlPathname?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};


/** Location details. */
export type LocationCitiesWithListingsArgs = {
  input?: InputMaybe<CityWithListingsInput>;
};


/** Location details. */
export type LocationCountiesWithListingsArgs = {
  input?: InputMaybe<CountyWithListingsInput>;
};


/** Location details. */
export type LocationLinkInfoArgs = {
  input: LinkInfoQueryInput;
};


/** Location details. */
export type LocationListingCountArgs = {
  input: ListingCountInput;
};


/** Location details. */
export type LocationListingSearchArgs = {
  filters?: InputMaybe<ListingSearchFiltersInput>;
  isPinSearch?: InputMaybe<Scalars['Boolean']>;
  isSemSort?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pipeline?: InputMaybe<PipelineInput>;
  preferences?: InputMaybe<SearchIoPersonalizationInput>;
  prioritizeHouses?: InputMaybe<Scalars['Boolean']>;
  prioritizeLocation?: InputMaybe<Scalars['Boolean']>;
  rentPricePreference?: InputMaybe<RentPricePreference>;
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  similarListings?: InputMaybe<SimilarListingsInput>;
  sort?: InputMaybe<ListingSort>;
  sortByDistance?: InputMaybe<Scalars['Boolean']>;
  sortRandomSeed?: InputMaybe<Scalars['String']>;
  sortVersion?: InputMaybe<Scalars['Int']>;
  useSoftFilters?: InputMaybe<Scalars['Boolean']>;
  withFeaturedListing?: InputMaybe<Scalars['Boolean']>;
  withInactiveListings?: InputMaybe<Scalars['Boolean']>;
  withPinDensity?: InputMaybe<Scalars['Boolean']>;
  withSpotlightListing?: InputMaybe<Scalars['Boolean']>;
};


/** Location details. */
export type LocationMonthlyRentDistributionArgs = {
  ranges: Array<Scalars['Int']>;
};


/** Location details. */
export type LocationNearbyCityLinksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** Location details. */
export type LocationNearbyCollegeLinksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** Location details. */
export type LocationNearbyHoodLinksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** Location details. */
export type LocationNearbyLinksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** Location details. */
export type LocationNearbyPoiLinksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** Location details. */
export type LocationNearbyPlacesArgs = {
  placesLimit?: InputMaybe<Scalars['Int']>;
  removeDuplicatesByName?: InputMaybe<Scalars['Boolean']>;
};


/** Location details. */
export type LocationNormalizedOverallRentArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  minFloorPlanValues?: InputMaybe<Scalars['Int']>;
};


/** Location details. */
export type LocationOverallRentArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** Location details. */
export type LocationOverallRentByMonthAndYearArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  minFloorPlanValues?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<MonthYearSort>;
};


/** Location details. */
export type LocationSchoolsByEducationLevelArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  radius?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortDirection>;
};


/** Location details. */
export type LocationSeoLinksArgs = {
  input: SeoLinksQueryInput;
};

export type LocationInput = {
  bbox?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  city?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['Float']>;
  /** Used by E2E */
  listingId?: InputMaybe<Scalars['ID']>;
  lng?: InputMaybe<Scalars['Float']>;
  locationSlug?: InputMaybe<Scalars['String']>;
  /** Used by E2E */
  propertyName?: InputMaybe<Scalars['String']>;
  /** Radius version to use for runnning experiments to make results more relevant for users */
  radiusVersion?: InputMaybe<Scalars['Int']>;
  schoolDistrictId?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export const enum LocationNearbyPlaceType {
  Bars = 'BARS',
  Cafes = 'CAFES',
  Community = 'COMMUNITY',
  Emergency = 'EMERGENCY',
  Entertainment = 'ENTERTAINMENT',
  Government = 'GOVERNMENT',
  Grocery = 'GROCERY',
  Highlights = 'HIGHLIGHTS',
  Parks = 'PARKS',
  Restaurants = 'RESTAURANTS',
  Services = 'SERVICES',
  Shopping = 'SHOPPING',
  SportsAndRec = 'SPORTS_AND_REC',
  Transportation = 'TRANSPORTATION'
};

export type LocationPercentageDifference = {
  national?: Maybe<Scalars['Int']>;
  perCapita?: Maybe<Scalars['Int']>;
  similarAreas?: Maybe<Scalars['Int']>;
};

export type LocationPercentileRentData = {
  bedCount?: Maybe<Array<PercentileRentByBedCount>>;
  overall?: Maybe<Scalars['Int']>;
};

export type LocationReview = {
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  overallRating?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type LocationReviews = {
  /** @deprecated Field no longer supported */
  count?: Maybe<Scalars['Int']>;
  /** @deprecated Field no longer supported */
  reviews?: Maybe<Array<Maybe<LocationReview>>>;
};

export type LocationReviewsByGroup = {
  /** @deprecated Field no longer supported */
  cons?: Maybe<LocationReviews>;
  /** @deprecated Field no longer supported */
  pros?: Maybe<LocationReviews>;
  /** @deprecated Field no longer supported */
  safety?: Maybe<LocationReviews>;
};

export type LocationReviewsByType = {
  cons: LocationReviewsResult;
  pros: LocationReviewsResult;
};


export type LocationReviewsByTypeConsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  resultsPerPage?: InputMaybe<Scalars['Int']>;
};


export type LocationReviewsByTypeProsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  resultsPerPage?: InputMaybe<Scalars['Int']>;
};

export type LocationReviewsResult = {
  reviews?: Maybe<Array<Maybe<LocationReview>>>;
  total?: Maybe<Scalars['Int']>;
};

/** Result shape for location searches. */
export type LocationSearchResult = {
  bbox?: Maybe<Array<Scalars['Float']>>;
  city?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locationSlug?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  seoPath?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateAbbr?: Maybe<Scalars['String']>;
  type: LocationSearchTypes;
};

export const enum LocationSearchTypes {
  City = 'CITY',
  College = 'COLLEGE',
  County = 'COUNTY',
  District = 'DISTRICT',
  Hood = 'HOOD',
  Military = 'MILITARY',
  Poi = 'POI',
  School = 'SCHOOL',
  State = 'STATE',
  Zip = 'ZIP'
};

export type MarginalRevenue = {
  avgConversionByRevenue?: Maybe<Scalars['String']>;
  avgConversionByRevenueNV?: Maybe<Scalars['String']>;
};

export type MarkAbusiveInput = {
  campaignId?: InputMaybe<Scalars['String']>;
  comment: Scalars['String'];
  listingId: Scalars['ID'];
  reasons: Array<Scalars['String']>;
  reviewId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MarkHelpfulInput = {
  campaignId?: InputMaybe<Scalars['String']>;
  listingId: Scalars['ID'];
  reviewId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MetasaurusSearchMarketCodes = {
  cbsa?: Maybe<Scalars['String']>;
  dma?: Maybe<Scalars['String']>;
  pdma?: Maybe<Scalars['String']>;
  submarket?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  truliaGeos?: Maybe<Scalars['String']>;
};

export type MlsInfo = {
  agentFirstName?: Maybe<Scalars['String']>;
  agentLastName?: Maybe<Scalars['String']>;
  brokerName?: Maybe<Scalars['String']>;
  feed?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMls?: Maybe<Scalars['Boolean']>;
};

/** defaults to descending */
export const enum MonthYearSort {
  Ascending = 'ascending',
  Descending = 'descending'
};

/** Average rent for a specific month and year */
export type MonthlyAverageRentType = {
  averageRent?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  leadSubmission?: Maybe<MutationResult>;
  /**
   * Send feeback to the Multi-Arm Bandit agent driving photo optimization.
   * Plays are impressions. Wins are interactions. The client determines what a play and win
   * are; the agent doesn't care. Click, scroll, expanding the drawer, whatever.
   */
  optimizePhotosFromFeedback?: Maybe<PhotoFeedbackMutationResult>;
  /**
   * An Event Service Event Submission for Mobile Push Notifications
   * Similar to userActivity, but with input arguments specific to mobile devices
   * since they're treated differently internally. If you're on web, use userActivity.
   * Otherwise, this might be what you want.
   */
  pushActivity: MutationResult;
  reviewListing?: Maybe<MutationResult>;
  reviewMarkAbusive?: Maybe<MutationResult>;
  reviewMarkHelpful?: Maybe<MutationResult>;
  /** Creates new save search */
  savedSearchCreate: SavedSearchCreateMutationResult;
  savedSearchDelete: SavedSearchDeleteMutationResult;
  savedSearchUpdate: SavedSearchUpdateMutationResult;
  tourSchedule?: Maybe<MutationResult>;
  tourScheduleWithLeadSubmission?: Maybe<MutationResult>;
  /** An Event Service Event Submission */
  userActivity: MutationResult;
  /** Add a called listing. */
  userAddCalledListing: MutationResult;
  /** Add an emailed listing. */
  userAddEmailedListing: MutationResult;
  /** Add a saved listing. */
  userAddSavedListing: MutationResult;
  /** Add a viewed listing. */
  userAddViewedListing: MutationResult;
  /** Create a user. */
  userCreate: User;
  /** Delete a user. */
  userDelete: MutationResult;
  /** Forwards an engagement event to Facebook / TikTok */
  userEvent: UserEventMutationResult;
  /**
   * Login user via Google auth.
   * @deprecated Use `loginSocial` instead
   */
  userGoogleLogin: User;
  /** Login user via email and password. */
  userLogin: User;
  userLoginSocial: User;
  /** Remove a saved listing. */
  userRemoveSavedListing: MutationResult;
  /** Sends a reset token to provided email. */
  userResetPassword: MutationResult;
  /** Validates reset token and sets a new password. */
  userResetPasswordConfirmation: UserPasswordConfirmationResult;
  /** Update a user. */
  userUpdate: UserUpdateResponse;
};


export type MutationLeadSubmissionArgs = {
  input?: InputMaybe<LeadSubmissionInput>;
};


export type MutationOptimizePhotosFromFeedbackArgs = {
  plays: Array<PhotoFeedback>;
  wins: Array<PhotoFeedback>;
};


export type MutationPushActivityArgs = {
  input: PushActivityInput;
};


export type MutationReviewListingArgs = {
  input: ListingReviewInput;
};


export type MutationReviewMarkAbusiveArgs = {
  input: MarkAbusiveInput;
};


export type MutationReviewMarkHelpfulArgs = {
  input: MarkHelpfulInput;
};


export type MutationSavedSearchCreateArgs = {
  input: SavedSearchCreateInput;
};


export type MutationSavedSearchDeleteArgs = {
  input: SavedSearchDeleteInput;
};


export type MutationSavedSearchUpdateArgs = {
  input?: InputMaybe<SavedSearchUpdateInput>;
};


export type MutationTourScheduleArgs = {
  input: ScheduleTourInput;
};


export type MutationTourScheduleWithLeadSubmissionArgs = {
  input: ScheduleTourWithLeadSubmissionInput;
};


export type MutationUserActivityArgs = {
  input: UserActivityInput;
};


export type MutationUserAddCalledListingArgs = {
  listingId: Scalars['ID'];
  zutronId: Scalars['ID'];
};


export type MutationUserAddEmailedListingArgs = {
  listingId: Scalars['ID'];
  zutronId: Scalars['ID'];
};


export type MutationUserAddSavedListingArgs = {
  listingId: Scalars['ID'];
  zutronId: Scalars['ID'];
};


export type MutationUserAddViewedListingArgs = {
  listingId: Scalars['ID'];
  zutronId: Scalars['ID'];
};


export type MutationUserCreateArgs = {
  input: UserCreateInput;
};


export type MutationUserDeleteArgs = {
  input: UserDeleteInput;
};


export type MutationUserEventArgs = {
  input: UserEventInput;
};


export type MutationUserGoogleLoginArgs = {
  input: UserGoogleLoginInput;
};


export type MutationUserLoginArgs = {
  input: UserLoginInput;
};


export type MutationUserLoginSocialArgs = {
  input: UserLoginSocialInput;
};


export type MutationUserRemoveSavedListingArgs = {
  listingId: Scalars['ID'];
  zutronId: Scalars['ID'];
};


export type MutationUserResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationUserResetPasswordConfirmationArgs = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  resetToken: Scalars['String'];
};


export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
};

/** Generic mutation result. */
export type MutationResult = {
  /** Optional message about the result of the mutation. */
  message?: Maybe<Scalars['String']>;
  /** Optional related data. */
  meta?: Maybe<Scalars['JSON']>;
  /** Whether or not the mutation succeeded. */
  success: Scalars['Boolean'];
};

export type NearbyCategoryItem = {
  category: Scalars['String'];
  places: Array<NearbySubcategoryItem>;
  /** If `removeDuplicatesByName` is true, then the total reflects uniquely named places in the category */
  total: Scalars['Int'];
};

export type NearbyListingSearchInput = {
  excludedListingIds?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  propertyAmenities?: InputMaybe<Array<Amenity>>;
  propertyLatitude: Scalars['Float'];
  propertyLongitude: Scalars['Float'];
  propertyMaxBed: Scalars['Int'];
  propertyMaxPrice: Scalars['Int'];
  propertyMinBed: Scalars['Int'];
  propertyMinPrice: Scalars['Int'];
};

export type NearbyPlaces = {
  bars: NearbyCategoryItem;
  cafes: NearbyCategoryItem;
  community: NearbyCategoryItem;
  emergency: NearbyCategoryItem;
  entertainment: NearbyCategoryItem;
  government: NearbyCategoryItem;
  grocery: NearbyCategoryItem;
  highlights: NearbyCategoryItem;
  parks: NearbyCategoryItem;
  restaurants: NearbyCategoryItem;
  services: NearbyCategoryItem;
  shopping: NearbyCategoryItem;
  sportsAndRecreation: NearbyCategoryItem;
  transportation: NearbyCategoryItem;
};

/** Nearby links based on a radial search of the provided geo, sorted by weightedScore */
export type NearbySeoLinks = {
  categorizedPois?: Maybe<CategorizedPois>;
  cities: Array<SeoLink>;
  colleges: Array<SeoLink>;
  militaryBases: Array<SeoLink>;
  neighborhoods: Array<SeoLink>;
  pois: Array<SeoLink>;
  zips: Array<SeoLink>;
};


/** Nearby links based on a radial search of the provided geo, sorted by weightedScore */
export type NearbySeoLinksCategorizedPoisArgs = {
  input?: InputMaybe<NearbySeoLinksInput>;
};


/** Nearby links based on a radial search of the provided geo, sorted by weightedScore */
export type NearbySeoLinksCitiesArgs = {
  input?: InputMaybe<NearbySeoLinksInput>;
};


/** Nearby links based on a radial search of the provided geo, sorted by weightedScore */
export type NearbySeoLinksCollegesArgs = {
  input?: InputMaybe<NearbySeoLinksInput>;
};


/** Nearby links based on a radial search of the provided geo, sorted by weightedScore */
export type NearbySeoLinksMilitaryBasesArgs = {
  input?: InputMaybe<NearbySeoLinksInput>;
};


/** Nearby links based on a radial search of the provided geo, sorted by weightedScore */
export type NearbySeoLinksNeighborhoodsArgs = {
  input?: InputMaybe<NearbySeoLinksInput>;
};


/** Nearby links based on a radial search of the provided geo, sorted by weightedScore */
export type NearbySeoLinksPoisArgs = {
  input?: InputMaybe<NearbySeoLinksInput>;
};


/** Nearby links based on a radial search of the provided geo, sorted by weightedScore */
export type NearbySeoLinksZipsArgs = {
  input?: InputMaybe<NearbySeoLinksInput>;
};

export type NearbySeoLinksInput = {
  /** limit results to this number, applies to all fields under this one, unless overridden */
  limit?: InputMaybe<Scalars['Float']>;
  /** minimum number of (fuzzy) listings required for a link, applies to all fields under this one, unless overridden */
  minListings?: InputMaybe<Scalars['Int']>;
  /** minimum priority score for a link, applies to all fields under this one, unless overridden */
  minPriorityScore?: InputMaybe<Scalars['Float']>;
  /** if specified, a minimum number of seo visits to the page, an alternate condition to priorityScore for qualifying links, applies to all fields under this one, unless overriden */
  orMinSeoVisits?: InputMaybe<Scalars['Int']>;
  /** radius for radial search, applies to all fields under this one, unless overridden */
  radiusInMiles?: InputMaybe<Scalars['Float']>;
  /** search type: based on filtering by city/state, or based on radius. defaults to radius */
  searchType?: InputMaybe<SeoLinksSearchTypeInput>;
  sort?: InputMaybe<GeoSort>;
};

export type NearbySubcategoryItem = {
  address: Scalars['String'];
  addressFull: Scalars['String'];
  city: Scalars['String'];
  distanceInMiles: Scalars['Float'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  name: Scalars['String'];
  state: Scalars['String'];
  subcategories: Array<Scalars['String']>;
  type: LocationNearbyPlaceType;
};

/** Average rent for a specific month and year */
export type NormalizedMonthlyAverageRentType = {
  averageRent: Scalars['Int'];
  displayDate: Scalars['String'];
  sortKey: Scalars['String'];
};

/** Average rent over time, broken down by floorplan, normalized for display */
export type NormalizedRentTrendsType = {
  oneBedroom?: Maybe<Array<NormalizedMonthlyAverageRentType>>;
  studio?: Maybe<Array<NormalizedMonthlyAverageRentType>>;
  threeBedroom?: Maybe<Array<NormalizedMonthlyAverageRentType>>;
  twoBedroom?: Maybe<Array<NormalizedMonthlyAverageRentType>>;
};

export type OfficeHour = {
  day: Scalars['String'];
  times: Array<OfficeHourTime>;
};

export type OfficeHourTime = {
  close?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['String']>;
};

export type OptimizedPhoto = {
  caption?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  plays: Scalars['Int'];
  wins: Scalars['Int'];
};

export type PaginatedThirdPartyReviews = {
  reviews: Array<Review>;
  total: Scalars['Int'];
};

export type Parking = {
  assigned?: Maybe<Scalars['Boolean']>;
  assignedFee?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  perSpaceFee?: Maybe<Scalars['String']>;
  totalSpaces?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type PercentileRentByBedCount = {
  bedCount: Scalars['Int'];
  value: Scalars['Int'];
};

export type PetPolicy = {
  /** Additional rent per month. */
  additionalRent?: Maybe<Scalars['Float']>;
  /** Comment about the pet. */
  comment?: Maybe<Scalars['String']>;
  /** Amount of deposit needed for pet. */
  deposit?: Maybe<Scalars['Int']>;
  /** Is Pet care offered here */
  hasPetCare: Scalars['Boolean'];
  /**
   * The ID corresponds to the pet label.
   * See src/listing/config/pet-policies.config.ts for id mappings
   */
  id?: Maybe<PetPolicyIds>;
  /** Initial fee required, string valued. */
  initialFee?: Maybe<Scalars['String']>;
  /** Description of the policy/label. */
  label?: Maybe<Scalars['String']>;
  /** Maximum number of pets allowed. */
  maximumPets?: Maybe<Scalars['Int']>;
  /** Maximum weight allowed for pet. */
  weightRestriction?: Maybe<Scalars['Int']>;
};

/** Pet policies to filter listings by. */
export const enum PetPolicyFilter {
  CatsAndDogsOk = 'CATS_AND_DOGS_OK',
  CatsOk = 'CATS_OK',
  DogsOk = 'DOGS_OK',
  NoPets = 'NO_PETS',
  PetFriendly = 'PET_FRIENDLY'
};

/** Pet policy types */
export const enum PetPolicyIds {
  Birds = 'BIRDS',
  BreedRestriction = 'BREED_RESTRICTION',
  CallForPolicy = 'CALL_FOR_POLICY',
  CatsAllowed = 'CATS_ALLOWED',
  DogsAllowed = 'DOGS_ALLOWED',
  Fish = 'FISH',
  NoPets = 'NO_PETS',
  NoRestrictions = 'NO_RESTRICTIONS',
  Other = 'OTHER',
  PetsAccepted = 'PETS_ACCEPTED',
  Reptiles = 'REPTILES',
  WeightRestriction = 'WEIGHT_RESTRICTION'
};

/** Primitive representing an image. */
export type Photo = {
  /** Caption (Optional) */
  caption?: Maybe<Scalars['String']>;
  /** Category (Optional) */
  category?: Maybe<Scalars['String']>;
  /** Cloudinary public iD */
  id: Scalars['String'];
};

export type PhotoFeedback = {
  listingId: Scalars['String'];
  photoId: Scalars['String'];
};

export type PhotoFeedbackMutationResult = {
  ok?: Maybe<Scalars['Boolean']>;
};

/** Arguments to be sent to the search.io pipeline (DEPRECATED - NO LONGER USED) */
export type PipelineInput = {
  /** The name of the pipeline to use. Typically this is "performance" This matches the `.yaml` filename generated by the truesearch repo and hosted by search.io. */
  name?: InputMaybe<Scalars['String']>;
  /** Options is any JSON to be passed through `variables`. Used for toggling boosts in Optimizely. */
  options?: InputMaybe<Scalars['JSON']>;
  /** The pipeline version is sent through Optimizely, with defaults added as environment variables loaded in `pipelineConfig`. */
  version?: InputMaybe<Scalars['String']>;
};

export type PointsOfInterest = {
  density?: Maybe<DensityAreas>;
  percentDifference?: Maybe<LocationPercentageDifference>;
  poi: Scalars['String'];
  score?: Maybe<Scalars['Int']>;
};

export type PointsOfInterestAggregates = {
  city: Array<PointsOfInterest>;
  hood: Array<PointsOfInterest>;
};

export const enum PreferredContactMethod {
  Email = 'EMAIL',
  Sms = 'SMS'
};

export type PriceDrops = {
  byBedroom?: Maybe<Bedrooms>;
  byFloorplanId: Array<Maybe<FloorplanDrop>>;
  overall?: Maybe<Scalars['Int']>;
};

export type PricePreferences = {
  fourBedHigh?: InputMaybe<Scalars['Int']>;
  high?: InputMaybe<Scalars['Int']>;
  low?: InputMaybe<Scalars['Int']>;
  oneBedHigh?: InputMaybe<Scalars['Int']>;
  studioHigh?: InputMaybe<Scalars['Int']>;
  threeBedHigh?: InputMaybe<Scalars['Int']>;
  twoBedHigh?: InputMaybe<Scalars['Int']>;
};

export type PropertyManagementCompany = {
  description?: Maybe<Scalars['String']>;
  /** Raw id from listing property management company. */
  id?: Maybe<Scalars['ID']>;
  logo?: Maybe<Photo>;
  /**
   * Raw name from listing property management company.
   * This field potentially includes a URL that has to be displayed for legal reasons.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * Sanitized name from listing property management company.
   * This field is a clone of the "name" field with URLs removed from it.
   */
  nameSanitized?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export const enum PropertyType {
  Apartments = 'APARTMENTS',
  Condo = 'CONDO',
  Duplex = 'DUPLEX',
  House = 'HOUSE',
  Townhome = 'TOWNHOME',
  Triplex = 'TRIPLEX'
};

/** Property types to filter listings by. */
export const enum PropertyTypeFilter {
  Apartments = 'APARTMENTS',
  Condos = 'CONDOS',
  Duplexes = 'DUPLEXES',
  Houses = 'HOUSES',
  Townhouses = 'TOWNHOUSES',
  Triplexes = 'TRIPLEXES'
};

export type PublicTransit = {
  agencies: Array<PublicTransitAgency>;
  stops: Array<PublicTransitStop>;
  stopsCountByType: Array<PublicTransitStopCount>;
};

export type PublicTransitAgency = {
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PublicTransitStop = {
  distanceInMiles?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  routes: Array<Scalars['String']>;
  type?: Maybe<PublicTransitStopType>;
};

export type PublicTransitStopCount = {
  count: Scalars['Int'];
  type: PublicTransitStopType;
};

export const enum PublicTransitStopType {
  Bus = 'BUS',
  Other = 'OTHER',
  Train = 'TRAIN'
};

export type PushActivityInput = {
  /** Email is optional for native devices. Send it if you have it. */
  email?: InputMaybe<Scalars['String']>;
  /** Must be at most 50 character(s) */
  firstName?: InputMaybe<Scalars['String']>;
  /** Must be at most 50 character(s) */
  lastName?: InputMaybe<Scalars['String']>;
  /** Listing ID this event is for */
  listingId: Scalars['String'];
  /** Whether or not this is a registered user, i.e. signed in */
  registered: Scalars['Boolean'];
  /** Device ID from native device */
  sfmcDeviceId: Scalars['String'];
  /** Must be one of the following: favorited, srp-interaction, view */
  type: Scalars['String'];
  /** Zutron session ID - ZID */
  zutronId: Scalars['String'];
};

export type Query = {
  /**
   * Data for the given Google Aerial View video, including url, success flag, and error message.
   * This query should be performed sparingly due to costs.
   *
   * Address is optional, but recommended. When included an error will be posted to Kafka indicating a
   * failure at the given address, and a background task will be initiated to generate a new video
   * for that listing.
   *
   * Address formatting: "Address Line 1, City, State Zip" (corresponds to `addressFull` on `listing`)
   * Example: "123 Fake St, New York, NY 10002"
   */
  aerialViewData: AerialViewVideoData;
  /** Search Listings by geoShape */
  geoListingSearch: ListingSearchResult;
  /**
   * Get a property listing by it's ID.
   *
   * "On-market" properties only
   */
  listing?: Maybe<Listing>;
  /** RentHQ 'Additional Messages and Disclaimer' open text field */
  listingFeesDisclaimer?: Maybe<Scalars['String']>;
  /** Search listings. */
  listingSearch: ListingSearchResult;
  /** Get a single property by it's ID, including "off-market" properties */
  listingWithOffMarket?: Maybe<Listing>;
  /** Get a property listings by their IDs. */
  listings: Array<Listing>;
  /** Location details. */
  location: Location;
  /**
   * Freeform location search. (Typically used for an autocomplete UX)
   *
   * query text examples:
   * ---------------------
   *
   * "atlanta" => CITY
   * "university of georgia" => COLLEGE
   * "30328" => ZIP
   * "six flags over georgia" => POI
   * "north springs high school" => SCHOOL
   * "fulton county" => COUNTY
   */
  locationSearch: Array<LocationSearchResult>;
  /**
   * Gets the currently logged in user.
   * The Zutron ID should be provided by a "zid" header.
   */
  me: User;
  /** Search for nearby properties with prioritized amenities and proximity */
  nearbyListingSearch?: Maybe<Array<Listing>>;
  /** Search listings by property name */
  propertySearch: ListingSearchResult;
  /** Returns recommended listings for a given user. */
  recommendedListingsForUser: RecommendedListingsForUserResult;
  /** @deprecated Use `redfinLinks` instead */
  redfinLink?: Maybe<Link>;
  redfinLinks?: Maybe<RedfinLinks>;
  rentEstimate?: Maybe<RentEstimateResult>;
  rentEstimateByAddress?: Maybe<RentEstimateResult>;
  review: Review;
  reviews?: Maybe<Reviews>;
  siteConfiguration?: Maybe<SiteConfiguration>;
  sparseListing: SparseListing;
  /** @deprecated Please use `listing.tourTimes` instead */
  tourTimes?: Maybe<Array<TourTime>>;
  /** Gets user by Zutron ID. */
  user: User;
  /**
   * Create an anonymous user.
   *
   * Will return an empty user containing a Zutron ID and a generated UUID.
   * The Zutron ID must be included with any subsequent CRUD operations.
   */
  userAnonymous: User;
  utilityEstimates?: Maybe<UtilityEstimates>;
  /** Gets previously viewed listings information and total by Zutron ID. */
  viewedListingsInfo?: Maybe<ViewedListingsResponse>;
  walkScore?: Maybe<WalkScore>;
};


export type QueryAerialViewDataArgs = {
  address?: InputMaybe<Scalars['String']>;
  videoId: Scalars['String'];
};


export type QueryGeoListingSearchArgs = {
  coordinates: Array<InputMaybe<Array<InputMaybe<Array<Scalars['Float']>>>>>;
  filters?: InputMaybe<ListingSearchFiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ListingSort>;
  withFeaturedListing?: InputMaybe<Scalars['Boolean']>;
  withInactiveListings?: InputMaybe<Scalars['Boolean']>;
  withSpotlightListing?: InputMaybe<Scalars['Boolean']>;
};


export type QueryListingArgs = {
  id: Scalars['ID'];
};


export type QueryListingFeesDisclaimerArgs = {
  listingId: Scalars['ID'];
};


export type QueryListingSearchArgs = {
  filters?: InputMaybe<ListingSearchFiltersInput>;
  isPinSearch?: InputMaybe<Scalars['Boolean']>;
  isSemSort?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pipeline?: InputMaybe<PipelineInput>;
  prioritizeHouses?: InputMaybe<Scalars['Boolean']>;
  prioritizeLocation?: InputMaybe<Scalars['Boolean']>;
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ListingSort>;
  sortByDistance?: InputMaybe<Scalars['Boolean']>;
  sortRandomSeed?: InputMaybe<Scalars['String']>;
  sortVersion?: InputMaybe<Scalars['Int']>;
  useSoftFilters?: InputMaybe<Scalars['Boolean']>;
  withFeaturedListing?: InputMaybe<Scalars['Boolean']>;
  withInactiveListings?: InputMaybe<Scalars['Boolean']>;
  withPinDensity?: InputMaybe<Scalars['Boolean']>;
  withSpotlightListing?: InputMaybe<Scalars['Boolean']>;
};


export type QueryListingWithOffMarketArgs = {
  id: Scalars['ID'];
};


export type QueryListingsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryLocationArgs = {
  input: LocationInput;
};


export type QueryLocationSearchArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  queryText: Scalars['String'];
};


export type QueryNearbyListingSearchArgs = {
  input: NearbyListingSearchInput;
};


export type QueryPropertySearchArgs = {
  page?: InputMaybe<Scalars['Int']>;
  propertyName: Scalars['String'];
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ListingSort>;
};


export type QueryRecommendedListingsForUserArgs = {
  input: RecommendedListingsForUserInput;
};


export type QueryRedfinLinkArgs = {
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  regionType?: InputMaybe<Scalars['String']>;
};


export type QueryRedfinLinksArgs = {
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  regionType?: InputMaybe<Scalars['String']>;
};


export type QueryRentEstimateArgs = {
  input: RentEstimateInput;
};


export type QueryRentEstimateByAddressArgs = {
  input: RentEstimateByAddressInput;
};


export type QueryReviewArgs = {
  id: Scalars['ID'];
};


export type QueryReviewsArgs = {
  input: ReviewsInput;
};


export type QuerySiteConfigurationArgs = {
  host: Scalars['String'];
};


export type QuerySparseListingArgs = {
  id: Scalars['ID'];
};


export type QueryTourTimesArgs = {
  input: TourTimesInput;
};


export type QueryUserArgs = {
  zutronId: Scalars['ID'];
};


export type QueryUtilityEstimatesArgs = {
  filters: UtilityEstimateFilters;
};


export type QueryViewedListingsInfoArgs = {
  input: ViewedListingInput;
};


export type QueryWalkScoreArgs = {
  listingId: Scalars['ID'];
};

/** Primitive representing a range. */
export type Range = {
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

/** Input representing a range. */
export type RangeInput = {
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
};

export type Rating = {
  count: Scalars['Int'];
  /** Percentage of overall ratings that are of this star rating. */
  percent?: Maybe<Scalars['Float']>;
  /** Star rating, 1 to 5. */
  rating: Scalars['Int'];
};

export type RecommendedListingsForUserInput = {
  page?: InputMaybe<Scalars['Int']>;
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  zid: Scalars['String'];
};

export type RecommendedListingsForUserResult = {
  /** Recommended listing ids. */
  listings: Array<Listing>;
  /** Total listings found. */
  total: Scalars['Int'];
};

export type RedfinForRentLinks = {
  apartments?: Maybe<Link>;
  condos?: Maybe<Link>;
  houses?: Maybe<Link>;
  townhomes?: Maybe<Link>;
};

export type RedfinForSaleLinks = {
  houses?: Maybe<Link>;
};

export type RedfinLinks = {
  forRent?: Maybe<RedfinForRentLinks>;
  forSale?: Maybe<RedfinForSaleLinks>;
};

/** Refinement links categorized as requested */
export type RefinedSeoLinks = {
  /** Studio, 1 Bedroom, 2 Bedroom, etc. */
  floorplans: Array<SeoLink>;
  /** all refinement links from categories not explicitly requested */
  other: Array<SeoLink>;
  /** price < $nnnn links */
  priceUnder: Array<SeoLink>;
};


/** Refinement links categorized as requested */
export type RefinedSeoLinksFloorplansArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** Refinement links categorized as requested */
export type RefinedSeoLinksOtherArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


/** Refinement links categorized as requested */
export type RefinedSeoLinksPriceUnderArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  priceSort?: InputMaybe<SortDirection>;
};

/** Bucketed monthly rent distribution */
export type RentBucket = {
  count: Scalars['Int'];
  gte: Scalars['Int'];
  lt?: Maybe<Scalars['Int']>;
  percent: Scalars['String'];
};

/** All monthly rent buckets with a total listings count */
export type RentDistribution = {
  buckets: Array<RentBucket>;
};

export type RentEstimateByAddressInput = {
  address: Scalars['String'];
  bedCount: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  radius?: InputMaybe<Scalars['Int']>;
};

export type RentEstimateInput = {
  bedCount: Scalars['Int'];
  latitude: Scalars['Float'];
  limit?: InputMaybe<Scalars['Int']>;
  longitude: Scalars['Float'];
  radius?: InputMaybe<Scalars['Int']>;
};

export type RentEstimateResult = {
  lat?: Maybe<Scalars['Float']>;
  listings: Array<Listing>;
  lng?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Int']>;
  mean?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};

export type RentPercentiles = {
  p25?: Maybe<LocationPercentileRentData>;
};

export const enum RentPricePreference {
  Cheap = 'CHEAP',
  Luxury = 'LUXURY'
};

/** Raw average rent over time, broken down by floorplan. */
export type RentTrendsType = {
  oneBedroom?: Maybe<Array<Maybe<MonthlyAverageRentType>>>;
  studio?: Maybe<Array<Maybe<MonthlyAverageRentType>>>;
  threeBedroom?: Maybe<Array<Maybe<MonthlyAverageRentType>>>;
  twoBedroom?: Maybe<Array<Maybe<MonthlyAverageRentType>>>;
};

export type RenterCardInput = {
  allowRenterCardShare?: InputMaybe<Scalars['Boolean']>;
  creditScore?: InputMaybe<Scalars['Int']>;
  employer?: InputMaybe<Scalars['String']>;
  income?: InputMaybe<Scalars['Int']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  moveInDate?: InputMaybe<Scalars['DateTime']>;
  petsId?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  roommates?: InputMaybe<Scalars['Int']>;
};

/** Listing review. */
export type Review = {
  author?: Maybe<Scalars['String']>;
  comments: Array<ReviewComment>;
  date: Scalars['DateTime'];
  helpfulCount: Scalars['Int'];
  id: Scalars['ID'];
  isCurrentManagement: Scalars['Boolean'];
  listing?: Maybe<Listing>;
  listingId: Scalars['ID'];
  rating: Scalars['Int'];
  review: Scalars['String'];
};

/** Listing comment. */
export type ReviewComment = {
  author: Scalars['String'];
  comment: Scalars['String'];
  date: Scalars['DateTime'];
};

/** Reviews search result */
export type Reviews = {
  reviews: Array<Review>;
  total: Scalars['Int'];
};

/** Reviews search by listing ID input. */
export type ReviewsInput = {
  listingId: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  userSort?: InputMaybe<Array<Sort>>;
};

/** Listing reference containing the date on which the user saved the listing. */
export type SavedListingReference = {
  /** Saved listing. */
  listing?: Maybe<Listing>;
  /** Date when user saved the listing. */
  savedAt?: Maybe<Scalars['DateTime']>;
};

export type SavedSearch = {
  amenities?: Maybe<Array<Amenity>>;
  bath?: Maybe<BathFilter>;
  baths?: Maybe<Array<BathFilter>>;
  bed?: Maybe<BedFilter>;
  beds?: Maybe<Array<BedFilter>>;
  id: Scalars['Int'];
  location: SavedSearchLocation;
  moveInDate?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationEmail: Scalars['Boolean'];
  notificationPush: Scalars['Boolean'];
  petPolicies?: Maybe<PetPolicyFilter>;
  prices?: Maybe<Range>;
  propertyTypes?: Maybe<Array<PropertyTypeFilter>>;
  rentPricePreference?: Maybe<RentPricePreference>;
  searchUrl: Scalars['String'];
  squareFeet?: Maybe<Range>;
  withDeals?: Maybe<Scalars['Boolean']>;
};

/** Input for creating a new saved search. */
export type SavedSearchCreateInput = {
  amenities?: InputMaybe<Array<Amenity>>;
  bath?: InputMaybe<BathFilter>;
  baths?: InputMaybe<Array<BathFilter>>;
  bed?: InputMaybe<BedFilter>;
  beds?: InputMaybe<Array<BedFilter>>;
  currentLocationNameInput?: InputMaybe<CurrentLocationNameInput>;
  /** Required if email notifications are enabled */
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  location: LocationInput;
  locationSeoPath?: InputMaybe<Scalars['String']>;
  locationType?: InputMaybe<LocationSearchTypes>;
  moveInDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notificationEmail: Scalars['Boolean'];
  notificationPush: Scalars['Boolean'];
  petPolicies?: InputMaybe<PetPolicyFilter>;
  prices?: InputMaybe<RangeInput>;
  propertyTypes?: InputMaybe<Array<PropertyTypeFilter>>;
  rentPricePreference?: InputMaybe<RentPricePreference>;
  searchUrl?: InputMaybe<Scalars['String']>;
  /** Required if push notifications are enabled */
  sfmcDeviceId?: InputMaybe<Scalars['String']>;
  sortByDistance?: InputMaybe<Scalars['Boolean']>;
  squareFeet?: InputMaybe<RangeInput>;
  withDeals?: InputMaybe<Scalars['Boolean']>;
  zutronId: Scalars['ID'];
};

export type SavedSearchCreateMutationResult = {
  savedSearch: SavedSearch;
};

export type SavedSearchDeleteInput = {
  /** Required if email notifications are enabled */
  email?: InputMaybe<Scalars['String']>;
  savedSearchId: Scalars['Int'];
  /** Required if push notifications are enabled */
  sfmcDeviceId?: InputMaybe<Scalars['String']>;
  zutronId: Scalars['ID'];
};

export type SavedSearchDeleteMutationResult = {
  success: Scalars['Boolean'];
};

export type SavedSearchLocation = {
  bbox?: Maybe<Array<Maybe<Scalars['Float']>>>;
  city?: Maybe<Scalars['String']>;
  hood?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locationSlug?: Maybe<Scalars['String']>;
  schoolDistrictId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type SavedSearchSummary = {
  id: Scalars['Int'];
  name: Scalars['String'];
  notificationEmail: Scalars['Boolean'];
  notificationPush: Scalars['Boolean'];
  searchUrl: Scalars['String'];
};

export type SavedSearchUpdateInput = {
  /** Required if email notifications are enabled */
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notificationEmail?: InputMaybe<Scalars['Boolean']>;
  notificationPush?: InputMaybe<Scalars['Boolean']>;
  savedSearchId: Scalars['Int'];
  /** Required if push notifications are enabled */
  sfmcDeviceId?: InputMaybe<Scalars['String']>;
  zutronId: Scalars['ID'];
};

export type SavedSearchUpdateMutationResult = {
  savedSearch: SavedSearchSummary;
};

export type ScheduleTourInput = {
  date: Scalars['TourTimeString'];
  disclaimer?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  fbData?: InputMaybe<LeadFacebookEventInput>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  listingId: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  moveInDate: Scalars['DateTime'];
  phone: Scalars['String'];
  preferredMethod: PreferredContactMethod;
  referrer?: InputMaybe<Scalars['String']>;
  /**
   * A comma separated list of consent entities. e.g. 'ag,advertiser'
   * See: https://github.com/rentpath/leads#sms_consent_entities
   */
  smsConsentEntities?: InputMaybe<Scalars['String']>;
  smsOptIn: Scalars['Boolean'];
  time?: InputMaybe<Scalars['TourTimeString']>;
  tourPropertyId?: InputMaybe<Scalars['String']>;
  tourProvider?: InputMaybe<Scalars['String']>;
  type: TourType;
  uat?: InputMaybe<Scalars['Boolean']>;
  udrTestMode?: InputMaybe<Scalars['Boolean']>;
  zutronId?: InputMaybe<Scalars['String']>;
};

export type ScheduleTourWithLeadSubmissionInput = {
  /** Preference for filing lease application online. */
  applicationOptIn?: InputMaybe<Scalars['Boolean']>;
  baths?: InputMaybe<Scalars['Int']>;
  beds?: InputMaybe<Scalars['Int']>;
  /** Typically comes from a cookie of the same name. Max length 19 digits. */
  campaignId?: InputMaybe<Scalars['String']>;
  /**
   * Position on the page where the lead form was submitted.
   * Example: 'inline', 'modal', 'photo-modal'
   */
  context?: InputMaybe<Scalars['String']>;
  /**
   * Identifies the device. Must be one of the following:
   *      *   - AD - Used for ads like Facebook and TikTok
   *      *   - ANDROID - Native Android apps
   *      *   - IOS - Native iOS apps
   *      *   - MOBILE - Any mobile device through the browser
   *      *   - WEB - Desktop
   */
  device: LeadDevice;
  /**
   * Fires a number that corresponds to an SEM campaign defined in the querystring as ef_id.
   * This is a querystring value and is set when a user comes in by clicking on an ad in a search engine.
   * Typically comes from a cookie of the same name.
   */
  efId?: InputMaybe<Scalars['String']>;
  /** Required: email address - Limit: 255 */
  email: Scalars['String'];
  /** When supplied, will check that email and email_confirmation values match. If the email_confirmation field is missing, this validation will be skipped. */
  emailConfirmation?: InputMaybe<Scalars['String']>;
  /** OpenSearch unique identifier for unit/floorplan (if unspecified, indicates interest at the listing level instead of a specific unit). */
  endecaId?: InputMaybe<Scalars['String']>;
  /** Related to adobe tracking pixel. */
  evTransId?: InputMaybe<Scalars['String']>;
  facebookData?: InputMaybe<LeadFacebookEventInput>;
  firstName: Scalars['String'];
  /** IPv4 or IPv6 IP address */
  ipAddress?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  leadPreferredMethodOfContact?: InputMaybe<LeadPreferredContact>;
  leadTypeId?: InputMaybe<LeadChannel>;
  listingId: Scalars['String'];
  /** Custom message sent to property. */
  message?: InputMaybe<Scalars['String']>;
  /** Moving date. Must be the current or a future date. Example: 2023-07-28 */
  moveDate: Scalars['DateTime'];
  /** Number of days until move. Used to compute moveDate. */
  moveDatePreference?: InputMaybe<Scalars['Int']>;
  optInBrochure?: InputMaybe<Scalars['Boolean']>;
  optInNewsletter?: InputMaybe<Scalars['Boolean']>;
  /** Punctuation and spaces are accepted but removed. The resulting phone number must be 10 digits. */
  phone: Scalars['String'];
  /** A formatted string using the min and max price. (e.g. $1100-$1300) */
  priceRange?: InputMaybe<Scalars['String']>;
  questionBeds?: InputMaybe<Array<InputMaybe<BedFilter>>>;
  questionIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  questionLeaseTerms?: InputMaybe<LeadLeaseTerm>;
  questionOccupants?: InputMaybe<LeadOccupants>;
  questionPets?: InputMaybe<LeadPets>;
  /** The page the tour was scheduled from. */
  referrer: Scalars['String'];
  /**
   * Filter refinements.
   * e.g. "2 Bedrooms", "2 Baths", "3 Stars & Above"
   */
  refinements?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** A GA session id used for tracking downstream. Use no_session_id_ + new Date().getTime() if not available. */
  sessionId?: InputMaybe<Scalars['String']>;
  /** Use all that apply. Creating a comma delimited list with the values should not exceed 100 characters. */
  smsConsentEntities?: InputMaybe<Array<InputMaybe<LeadSmsConsentEntity>>>;
  smsOptIn: Scalars['Boolean'];
  /** GA hit id derived from window.hit_id */
  taggingHitId?: InputMaybe<Scalars['String']>;
  tourDateTime: Scalars['TourTimeString'];
  tourDisclaimer: Scalars['String'];
  tourPreferredMethodOfContact: PreferredContactMethod;
  tourType: TourType;
  tvisit?: InputMaybe<Scalars['String']>;
  website: LeadWebsite;
};

/** A school. */
export type School = {
  address?: Maybe<Scalars['String']>;
  /** @deprecated This field is no longer provided from the vendor. */
  averageRating?: Maybe<Scalars['String']>;
  /** @deprecated This field is no longer provided from the vendor. */
  averageRatingCount?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  districtIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  districtName?: Maybe<Scalars['String']>;
  /** @deprecated This field is no longer provided from the vendor. */
  educationLevels?: Maybe<Array<Maybe<Scalars['String']>>>;
  grade?: Maybe<Scalars['String']>;
  greatSchoolsLink?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locationSlug?: Maybe<Scalars['String']>;
  milesToGeoCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  overallRating?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  stateAbbrev?: Maybe<Scalars['String']>;
  subtypes?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type SchoolsByEducationLevel = {
  elementarySchools: Array<Maybe<School>>;
  highSchools: Array<Maybe<School>>;
  middleSchools: Array<Maybe<School>>;
};

export type SearchIoMeta = {
  filterRelevance?: Maybe<Array<Maybe<SoftFiltersRelevance>>>;
};

/** Arguments for search.io personalization inputs */
export type SearchIoPersonalizationInput = {
  bath?: InputMaybe<BathPreferences>;
  bed?: InputMaybe<BedPreferences>;
  price?: InputMaybe<PricePreferences>;
  rpVisitId?: InputMaybe<Scalars['String']>;
  searchCompletedSession?: InputMaybe<Scalars['String']>;
};

export type Section = {
  hidden?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<SectionImage>>;
  videos?: Maybe<Array<SectionVideo>>;
};

export type SectionGroup = {
  about?: Maybe<Section>;
  amenities?: Maybe<Section>;
  contact?: Maybe<Section>;
  floorplans?: Maybe<Section>;
  footer?: Maybe<Section>;
  gallery?: Maybe<Section>;
  gettingAround?: Maybe<Section>;
  header?: Maybe<Section>;
  hero?: Maybe<Section>;
  location?: Maybe<Section>;
  navigation?: Maybe<Section>;
  pets?: Maybe<Section>;
};

/** A Property Sites Section */
export type SectionImage = {
  alt?: Maybe<Scalars['String']>;
  assetId: Scalars['String'];
  id: Scalars['String'];
};

export type SectionVideo = {
  assetId: Scalars['String'];
  poster?: Maybe<Scalars['String']>;
  src: Scalars['String'];
};

/** An SEO link */
export type SeoLink = {
  /** The current average rent for a one bedroom in this location, across all property types and refinements, when available */
  avgOneBedroomRent?: Maybe<Scalars['Int']>;
  /** The current average rent for a studio in this location, across all property types and refinements, when available */
  avgStudioRent?: Maybe<Scalars['Int']>;
  /** The current average rent for a three bedroom in this location, across all property types and refinements, when available */
  avgThreeBedroomRent?: Maybe<Scalars['Int']>;
  /** The current average rent for a two bedroom in this location, across all property types and refinements, when available */
  avgTwoBedroomRent?: Maybe<Scalars['Int']>;
  /** The city slug for the link */
  citySlug: Scalars['String'];
  /** The display name for the returned link */
  displayName: Scalars['String'];
  /** The distance of this link's location from the search location */
  distanceInMiles?: Maybe<Scalars['Float']>;
  /** An expanded "fuzzy" count of the listings available on link */
  fuzzyListingCount: Scalars['Int'];
  /** The geoId code for the location defined by link */
  geoId: Scalars['String'];
  /** The count of the listings available on link */
  listingCount: Scalars['Int'];
  /** The location type (City, Hood, etc.) defined by link */
  locationType: Scalars['String'];
  /** The number of page views for this link */
  pageViews: Scalars['Int'];
  /** For poi links, the category of link */
  poiCategory?: Maybe<Scalars['String']>;
  /** The "score" for link, higher is better */
  priorityScore: Scalars['Float'];
  /** The property type (apartment, house, etc) for the link */
  propertyType: Scalars['String'];
  /** The "raw" display name directly from the index for the returned link */
  rawDisplayName: Scalars['String'];
  /** The display name for the refinement, if this link is refined */
  refinement?: Maybe<Scalars['String']>;
  /** The url slug of the refinement, if this link is refined */
  refinementSlug?: Maybe<Scalars['String']>;
  /** The count of SEO visits to this URL */
  seoVisits: Scalars['Int'];
  /** Sort key for this entry, for an ascending sort */
  sortKey: Scalars['Float'];
  /** The state slug for the link */
  stateSlug: Scalars['String'];
  /** The date of the last update for the current average rent for in this location */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The SEO link */
  url: Scalars['String'];
  /** A computed rank, based on priority score and distance */
  weightedRank: Scalars['Float'];
  /** The annual rent change percent for a one bedroom in this location, when available */
  yoyOneBedroomRentPct?: Maybe<Scalars['String']>;
  /** The annual rent change percent for a studio in this location, when available */
  yoyStudioRentPct?: Maybe<Scalars['String']>;
  /** The annual rent change percent for a three bedroom in this location, when available */
  yoyThreeBedroomRentPct?: Maybe<Scalars['String']>;
  /** The annual rent change percent for a two bedroom in this location, when available */
  yoyTwoBedroomRentPct?: Maybe<Scalars['String']>;
};

/** returned links should be for this location type */
export const enum SeoLinkLocation {
  City = 'city',
  College = 'college',
  Hood = 'hood',
  Military = 'military',
  Poi = 'poi',
  Zip = 'zip'
};

/** Returns scored SEO links meeting (minimum score and minimum listings) OR minimum seo visit thresholds */
export type SeoLinks = {
  /** Average rent data for the city associated with the location */
  averageRentData?: Maybe<SeoLink>;
  /** Categorized refinement links for a given geoId and locationType */
  categorizedRefinements?: Maybe<RefinedSeoLinks>;
  /** Returns cities within provided state */
  citiesByState: Array<SeoLink>;
  nearby: NearbySeoLinks;
  /** Popular refinements for a given geoId and locationType */
  popularRefinements: Array<SeoLink>;
  /** Property type links different from the propertyType passed */
  propertyTypes: Array<SeoLink>;
};


/** Returns scored SEO links meeting (minimum score and minimum listings) OR minimum seo visit thresholds */
export type SeoLinksAverageRentDataArgs = {
  input?: InputMaybe<SeoLinksInput>;
};


/** Returns scored SEO links meeting (minimum score and minimum listings) OR minimum seo visit thresholds */
export type SeoLinksCategorizedRefinementsArgs = {
  input?: InputMaybe<SeoLinksInput>;
};


/** Returns scored SEO links meeting (minimum score and minimum listings) OR minimum seo visit thresholds */
export type SeoLinksCitiesByStateArgs = {
  input?: InputMaybe<CitiesByStateInput>;
};


/** Returns scored SEO links meeting (minimum score and minimum listings) OR minimum seo visit thresholds */
export type SeoLinksPopularRefinementsArgs = {
  input?: InputMaybe<SeoLinksInput>;
};


/** Returns scored SEO links meeting (minimum score and minimum listings) OR minimum seo visit thresholds */
export type SeoLinksPropertyTypesArgs = {
  input?: InputMaybe<SeoLinksInput>;
};

export type SeoLinksInput = {
  /** limit results to this number, applies to all fields under this one, unless overridden */
  limit?: InputMaybe<Scalars['Float']>;
  /** minimum number of (fuzzy) listings required for a link, applies to all fields under this one, unless overridden */
  minListings?: InputMaybe<Scalars['Int']>;
  /** minimum priority score for a link, applies to all fields under this one, unless overridden */
  minPriorityScore?: InputMaybe<Scalars['Float']>;
  /** if specified, a minimum number of seo visits to the page, an alternate condition to priorityScore for qualifying links, applies to all fields under this one, unless overriden */
  orMinSeoVisits?: InputMaybe<Scalars['Int']>;
  /** The URL to query for (ex: /georgia/atlanta-apartments) */
  url?: InputMaybe<Scalars['String']>;
};

export const enum SeoLinksPropertyTypeInput {
  Apartments = 'apartments',
  Condos = 'condos',
  Houses = 'houses',
  Townhouses = 'townhouses'
};

export type SeoLinksQueryInput = {
  /** Filter this link out of the results */
  exceptPath?: InputMaybe<Scalars['String']>;
  /** Optional Lat Lng to be able to search links by particular location */
  lat?: InputMaybe<Scalars['Float']>;
  /** limit returned links to this number, defaults to 50 */
  limit?: InputMaybe<Scalars['Int']>;
  lng?: InputMaybe<Scalars['Float']>;
  /** The propertyType for the returned links */
  propertyType?: InputMaybe<SeoLinksPropertyTypeInput>;
};

export const enum SeoLinksSearchTypeInput {
  City = 'CITY',
  Radius = 'RADIUS'
};

export type SeoMetaDataWalkScore = {
  meanBikescore?: Maybe<Scalars['Int']>;
  meanTransitscore?: Maybe<Scalars['Int']>;
  meanWalkscore?: Maybe<Scalars['Int']>;
};

export type SeoMetadata = {
  /** demographics are normalized data for age, commute, family size, household earnings, and education for a location */
  demographics: Demographics;
  /** locationWalkScores are walkscores for a location and come from seometadata. These are different than walkscores for a property */
  locationWalkScores: WalkScores;
  pointsOfInterest: PointsOfInterestAggregates;
  surveyAggregates: SurveyAggregates;
};

/** Arguments to be sent to the search.io for recommendation pipeline */
export type SimilarListingsInput = {
  /** The listing latitude */
  propertyLatitude?: InputMaybe<Scalars['String']>;
  /** The listing id */
  propertyListingId?: InputMaybe<Scalars['String']>;
  /** The listing longitude */
  propertyLongitude?: InputMaybe<Scalars['String']>;
  /** The listing max bed value */
  propertyMaxBed?: InputMaybe<Scalars['String']>;
  /** The listing max price value */
  propertyMaxPrice?: InputMaybe<Scalars['String']>;
  /** The listing min bed value */
  propertyMinBed?: InputMaybe<Scalars['String']>;
  /** The listing min price value */
  propertyMinPrice?: InputMaybe<Scalars['String']>;
};

/** A Property Websites Configuration Record */
export type SiteConfiguration = {
  assets: AssetsType;
  chatbots?: Maybe<ChatbotsType>;
  gtagId?: Maybe<Scalars['String']>;
  host: Scalars['String'];
  listingId: Scalars['String'];
  propertyDescription?: Maybe<Scalars['String']>;
  rentPayLink?: Maybe<Scalars['String']>;
  rentSearchPixel?: Maybe<Scalars['String']>;
  residentPortalLink?: Maybe<Scalars['String']>;
  sections?: Maybe<SectionGroup>;
  seedColor: Scalars['String'];
  suppliedGTMId?: Maybe<Scalars['String']>;
  template: Scalars['String'];
  variation: Scalars['String'];
  withGettingAround: Scalars['Boolean'];
  withStreetView: Scalars['Boolean'];
};

export type SocialLinks = {
  facebookLink?: Maybe<Scalars['String']>;
  instagramLink?: Maybe<Scalars['String']>;
  twitterLink?: Maybe<Scalars['String']>;
  youtubeLink?: Maybe<Scalars['String']>;
};

export const enum SocialLoginProvider {
  Apple = 'APPLE',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE'
};

export type SoftFiltersRelevance = {
  listingId?: Maybe<Scalars['String']>;
  matched?: Maybe<Array<Maybe<Scalars['String']>>>;
  unmatched?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Reviews Sort */
export type Sort = {
  direction?: InputMaybe<SortDirection>;
  name?: InputMaybe<Scalars['String']>;
};

/** Sort Direction */
export const enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
};

/**
 * A SparseListing is a variation of the Listing object that only shares a subset
 * of fields with a typical listing, but that also includes additional fields not
 * present in a Listing object.
 * An example of a SparseListing is a CoreLogic listing.
 */
export type SparseListing = {
  /** Short listing address. */
  address?: Maybe<Scalars['String']>;
  /** Combined listing address. */
  addressFull?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Listing location details. */
  location?: Maybe<Location>;
  name?: Maybe<Scalars['String']>;
  squareFeetText?: Maybe<Scalars['String']>;
};

export type SquareFeetRange = {
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};

/**
 * Survey results for a location.
 * Each item is the number of respondents that replied "yes" out of responseCount.
 */
export type SurveyAffirmations = {
  adequatePolice: Scalars['Int'];
  bars: Scalars['Int'];
  bikeFriendly: Scalars['Int'];
  busyStreets: Scalars['Int'];
  carNotRequired: Scalars['Int'];
  graffiti: Scalars['Int'];
  highCrime: Scalars['Int'];
  kidsPlayOutside: Scalars['Int'];
  lateNoise: Scalars['Int'];
  litter: Scalars['Int'];
  mostlyNewer: Scalars['Int'];
  mostlyOlder: Scalars['Int'];
  nearDowntown: Scalars['Int'];
  nearGroceryStores: Scalars['Int'];
  nearHighways: Scalars['Int'];
  nearParks: Scalars['Int'];
  nearPublicTransport: Scalars['Int'];
  nearSchools: Scalars['Int'];
  niceView: Scalars['Int'];
  petFriendly: Scalars['Int'];
  potHoles: Scalars['Int'];
  quiet: Scalars['Int'];
  raiseFamily: Scalars['Int'];
  safeFamilies: Scalars['Int'];
  safeLeavingPackages: Scalars['Int'];
  safeSpareKey: Scalars['Int'];
  safeUnlockedDoors: Scalars['Int'];
  safeWalkingAtNight: Scalars['Int'];
  sideWalks: Scalars['Int'];
  treeLined: Scalars['Int'];
  walkable: Scalars['Int'];
  wellLit: Scalars['Int'];
  wellMaintained: Scalars['Int'];
};

export type SurveyAggregates = {
  city?: Maybe<SurveyResult>;
  hood?: Maybe<SurveyResult>;
};

/** Location survey ratings. Scale is from 1 to 5. */
export type SurveyRatings = {
  affordableRating: Scalars['Int'];
  livingRating: Scalars['Int'];
  safetyRating: Scalars['Int'];
  schoolRating: Scalars['Int'];
};

export type SurveyResult = {
  affirmations: SurveyAffirmations;
  ratings: SurveyRatings;
  responseCount: Scalars['Int'];
};

export type TenantPaidUtilitiesInfo = {
  bedCount?: Maybe<Scalars['Int']>;
  isEstimate?: Maybe<Scalars['Boolean']>;
  monthlyTotal?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  utilities?: Maybe<Array<Scalars['String']>>;
};

export type ThirdPartyAggregates = {
  aggregatedRating: Scalars['Float'];
  ratings?: Maybe<Array<Rating>>;
  source: Scalars['String'];
  total: Scalars['Int'];
};

export type ThirdPartyReview = {
  aggregates?: Maybe<Array<ThirdPartyAggregates>>;
  reviews?: Maybe<PaginatedThirdPartyReviews>;
};


export type ThirdPartyReviewReviewsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  source: Scalars['String'];
};

export type TourProviderDetails = {
  disclaimer?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
  providerKey?: Maybe<Scalars['String']>;
  smsContentEntities?: Maybe<Scalars['String']>;
  smsOptIn?: Maybe<Scalars['Boolean']>;
  types?: Maybe<TourProviderTypes>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TourProviderTypes = {
  inPerson?: Maybe<Scalars['Boolean']>;
  selfGuided?: Maybe<Scalars['Boolean']>;
  videoCall?: Maybe<Scalars['Boolean']>;
};

/** Available Tour Time */
export type TourTime = {
  /** date of available tours */
  date?: Maybe<Scalars['TourTimeString']>;
  /** available tour times for date */
  times?: Maybe<Array<Scalars['TourTimeString']>>;
};

export type TourTimesInput = {
  dates: Array<Scalars['DateTime']>;
  tourPropertyId: Scalars['String'];
  tourProvider: Scalars['String'];
  type: TourType;
  zutronId: Scalars['String'];
};

export const enum TourType {
  InPerson = 'IN_PERSON',
  SelfGuided = 'SELF_GUIDED',
  VideoCall = 'VIDEO_CALL'
};

/** Search IO Tracking Type */
export const enum TrackingType {
  None = 'NONE',
  PosNeg = 'POS_NEG'
};

/** The Transit Score */
export type TransitType = {
  description?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  summary?: Maybe<Scalars['String']>;
};

/** A user. */
export type User = {
  /** The user's email. */
  email?: Maybe<Scalars['String']>;
  /** The user's last name. */
  familyName?: Maybe<Scalars['String']>;
  /** The user's full name. */
  fullName?: Maybe<Scalars['String']>;
  /** The user's first name. */
  givenName?: Maybe<Scalars['String']>;
  /** The user's ID. */
  id?: Maybe<Scalars['ID']>;
  /** Collection of saved listings. */
  savedListings: Array<SavedListingReference>;
  /** List of saved listings IDs. */
  savedListingsIds: Array<Scalars['ID']>;
  /** Collection of saved searches */
  savedSearches: Array<SavedSearch>;
  /** The user's Zutron ID. */
  zutronId: Scalars['ID'];
};

export type UserActivityInput = {
  email: Scalars['String'];
  /** Must be at most 50 character(s) */
  firstName?: InputMaybe<Scalars['String']>;
  /** Must be at most 50 character(s) */
  lastName?: InputMaybe<Scalars['String']>;
  listingId: Scalars['String'];
  registered: Scalars['Boolean'];
  /** must be one of the following: favorited, srp-interaction, view */
  type: Scalars['String'];
  zutronId: Scalars['String'];
};

/** Fields required to create a user. */
export type UserCreateInput = {
  /** Must be a valid email. */
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  password: Scalars['String'];
  /** Personalized renter details */
  renterCard?: InputMaybe<RenterCardInput>;
  zutronId: Scalars['String'];
};

/** Fields required to delete a user. */
export type UserDeleteInput = {
  zutronId: Scalars['String'];
};

export type UserDetailUpdateResponse = {
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** Input for a user engagement event submitted via server to FB or TikTok (page view or phone lead) */
export type UserEventInput = {
  email?: InputMaybe<Scalars['String']>;
  /** Event transaction id - identifier for lead events */
  evTransId?: InputMaybe<Scalars['String']>;
  eventType: UserEventType;
  ipSrc: IpSrcInput;
  leadType?: InputMaybe<UserEventLeadType>;
  /** Id of the selected listing, when applicable */
  listingId?: InputMaybe<Scalars['String']>;
  /** Revenue for the selected listing, when applicable */
  listingRevenue?: InputMaybe<Scalars['Float']>;
  routeName?: InputMaybe<UserEventRouteName>;
  /** Active test event code provided by either the Facebook or TikTok events consoles */
  testEventId?: InputMaybe<Scalars['String']>;
  /** Tracking cookie for TikTok pixel */
  ttpCookie?: InputMaybe<Scalars['String']>;
  /** Page url when the event occurred. */
  url: Scalars['String'];
  userAgent: Scalars['String'];
};

export const enum UserEventLeadType {
  PhoneLead = 'PHONE_LEAD',
  ScheduleTourLead = 'SCHEDULE_TOUR_LEAD'
};

export type UserEventMutationResult = {
  facebookResponse: MutationResult;
  tiktokResponse?: Maybe<MutationResult>;
};

export const enum UserEventRouteName {
  Detail = 'DETAIL',
  ListSearch = 'LIST_SEARCH'
};

export const enum UserEventType {
  Lead = 'LEAD',
  PageView = 'PAGE_VIEW'
};

/** Fields required to update a user with google. */
export type UserGoogleLoginInput = {
  jwtToken: Scalars['String'];
  zutronId: Scalars['String'];
};

/** Fields required to log in a user. */
export type UserLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  zutronId: Scalars['String'];
};

/** Fields required to update a user with social providers. */
export type UserLoginSocialInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  provider: SocialLoginProvider;
  /** Either a JWT token or an access token depending on the provider. */
  token: Scalars['String'];
  zutronId: Scalars['String'];
};

/** Fields of the user password confirmation result */
export type UserPasswordConfirmationResult = {
  /** Optional message about the result of the mutation. */
  message?: Maybe<Scalars['String']>;
  /** Whether or not the mutation succeeded. */
  success: Scalars['Boolean'];
  /** Zutron ID */
  zid?: Maybe<Scalars['String']>;
};

/** Fields to update a user. */
export type UserUpdateInput = {
  email?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  /** Personalized renter details */
  renterCard?: InputMaybe<RenterCardInput>;
  zutronId: Scalars['String'];
};

export type UserUpdateResponse = {
  /** Optional message about the result of the mutation. */
  message?: Maybe<Scalars['String']>;
  /** Optional related data. */
  meta?: Maybe<Scalars['JSON']>;
  /** Whether or not the mutation succeeded. */
  success: Scalars['Boolean'];
  /** Updated user data */
  user: UserDetailUpdateResponse;
};

export type UtilitiesFeeInfo = {
  included?: Maybe<IncludedUtilitiesInfo>;
  tenantPaid: Array<TenantPaidUtilitiesInfo>;
};

export type UtilityEstimate = {
  /** The average cost per kilowatt-hour (kWh) for the address. */
  priceAverage?: Maybe<Scalars['Int']>;
  /** Estimated monthly bill amount. */
  priceRange?: Maybe<Range>;
  /** The estimated total for the year in kilowatt-hours (kWh). */
  usage?: Maybe<Scalars['String']>;
};

export type UtilityEstimateFilters = {
  address?: InputMaybe<Scalars['String']>;
  bedrooms?: InputMaybe<Scalars['Int']>;
  squareFeet?: InputMaybe<Scalars['Int']>;
  zip: Scalars['String'];
};

export type UtilityEstimateListingFilters = {
  bedrooms?: InputMaybe<Scalars['Int']>;
};

export type UtilityEstimates = {
  electricity?: Maybe<UtilityEstimate>;
  /** @deprecated No longer using this data from our current vendor. */
  gas?: Maybe<UtilityEstimate>;
};

export type Video = {
  idx?: Maybe<Scalars['Int']>;
  imageToken?: Maybe<Scalars['String']>;
  thumbUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
};

export type ViewedListingInput = {
  /** Up to maximum of 50 */
  count: Scalars['Int'];
  daysSinceLastViewed?: InputMaybe<Scalars['Int']>;
  excludedListingId?: InputMaybe<Scalars['String']>;
  zutronId: Scalars['ID'];
};

/** Listing reference containing the dates on which the user viewed and contacted the listing. */
export type ViewedListingReference = {
  /** Date when user contacted the listing, if applicable. */
  contactedAt?: Maybe<Scalars['DateTime']>;
  /** Viewed listing. */
  listing: Listing;
  /** Date when user viewed the listing. */
  viewedAt: Scalars['DateTime'];
};

export type ViewedListingsResponse = {
  /** Total number of available viewed listings, maximum of 50 */
  total?: Maybe<Scalars['Int']>;
  viewedListings?: Maybe<Array<ViewedListingReference>>;
};

/** A Walk Score Record */
export type WalkScore = {
  bike?: Maybe<BikeType>;
  category?: Maybe<CategoryScoresType>;
  transit?: Maybe<TransitType>;
  walk?: Maybe<WalkType>;
};

export type WalkScores = {
  city: SeoMetaDataWalkScore;
  hood: SeoMetaDataWalkScore;
};

/** The Walk Score */
export type WalkType = {
  description?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['Float']>;
};
