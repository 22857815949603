'use client'

import { useCallback, useRef } from 'react'
import { yieldOrContinue } from 'main-thread-scheduling'
import { ReactComponent as MenuRedesignIcon } from '@brand/icons/menu-redesign.svg'
import { ReactComponent as CloseIcon } from '@brand/icons/close.svg'
import { list } from '@brand/config/shared-links'
import { DialogModal } from '../../../../../features/dialog-modal/dialog-modal'
import { DialogModalCloseButton } from '../../../../../features/dialog-modal/dialog-modal-close-button'
import { DrawerMenuUserLinks } from '../../../../../features/page-header/drawer-menu/user-links'
import { IconButton } from '../../../../../components/icon-button/icon-button'
import { SavedSearchesLink } from '../../../../../features/page-header/drawer-menu/saved-searches-link'
import { SavedPropertiesLink } from '../../../../../features/page-header/drawer-menu/saved-properties-link'
import type { DrawerMenu_QueryFragment } from '../../../../../features/page-header/drawer-menu/__generated__/drawer-menu.gql'

import styles from './drawer-menu.module.css'

/** Needed only because the Rent app uses it */
export type DrawerMenuOwnProps = {
  marketTrendsUrl?: string | null
}

export type DrawerMenuProps = DrawerMenuOwnProps & DrawerMenu_QueryFragment

export function DrawerMenu(_props: DrawerMenuProps) {
  const ref = useRef<HTMLDialogElement>(null)

  const handleOpenMenu = useCallback(async function handleOpenMenu() {
    await yieldOrContinue('idle')
    ref.current?.showModal()

    await yieldOrContinue('smooth')
    window.eventTracker?.track('click', {
      section: 'menu',
      item: 'hamburger',
    })
  }, [])

  const handleCloseMenu = useCallback(async function handleCloseMenu() {
    await yieldOrContinue('interactive')
    ref.current?.close()
  }, [])

  return (
    <>
      <button
        aria-label="Open main navigation menu"
        data-tag_action="ignore"
        data-tid="drawer-menu-open-btn"
        onClick={handleOpenMenu}
        className={styles.hamburgerButton}
        type="button"
      >
        <MenuRedesignIcon aria-hidden height={14} width={16} />
      </button>
      <DialogModal
        ref={ref}
        entranceAnimation="slide-from-left"
        id="hamburger-menu"
        className={styles.dialog}
      >
        <nav
          aria-label="Main navigation menu"
          className={styles.menu}
          data-tid="drawer-menu"
        >
          <header className={styles.header}>
            <DialogModalCloseButton ref={ref} data-tag_section="menu">
              <IconButton
                data-tid="drawer-menu-close-btn"
                icon={<CloseIcon aria-hidden="true" />}
                id="hambuger-menu-close-btn"
                label={
                  <>
                    Close<span className="sr-only"> navigation menu</span>
                  </>
                }
                aria-label="Close navigation menu"
                variant="anchor"
                iconPosition="right"
                type="button"
                data-tag_action="ignore"
              />
            </DialogModalCloseButton>
          </header>
          <div className={styles.body}>
            <ul className={styles.list} id="drawer-menu-list">
              <li>
                <SavedPropertiesLink text="Saved Apartments" />
              </li>
              <li>
                <SavedSearchesLink onClick={handleCloseMenu} />
              </li>
              <li>
                <a
                  href="/blog/moving-center/"
                  data-tag_item="moving_center"
                  target="_blank"
                  className={styles.item}
                >
                  Moving Center
                </a>
              </li>
              <li>
                <a
                  href={list.URL}
                  data-tag_item="list_a_property"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.item}
                >
                  {list.TEXT}
                </a>
              </li>
              <li>
                <a
                  href="https://solutions.rent.com"
                  data-tag_item="business_solutions"
                  className={styles.item}
                  target="_blank"
                  rel="noreferrer"
                >
                  Marketing Solutions
                </a>
              </li>
              <li>
                <a
                  href="/blog/how-much-rent-can-i-afford"
                  data-tag_item="rent-calculator"
                  target="_blank"
                  className={styles.item}
                >
                  Rent Calculator
                </a>
              </li>
              <li>
                <a
                  href="/blog/"
                  data-tag_item="blog"
                  target="_blank"
                  className={styles.item}
                >
                  Blog
                </a>
              </li>
              <li className={styles.divider}>
                <a
                  href="/mobile-apps"
                  data-tag_item="download_apps"
                  className={styles.item}
                >
                  Download the App
                </a>
              </li>

              <DrawerMenuUserLinks handleClose={handleCloseMenu} />
            </ul>
          </div>
        </nav>
      </DialogModal>
    </>
  )
}
