'use client'

import { ReactComponent as HeartFilledIcon } from '@brand/icons/heart-filled.svg'
import { ReactComponent as HeartIcon } from '@brand/icons/heart.svg'
import clsx from 'clsx'
import { useAtomValue } from 'jotai'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { userTotalSavedListings } from '../user/user.store'
import styles from '@brand/slots/page-header/page-header.module.css'
import { useFeatureVariable } from '@rentpath/ab-testing-react'

export function PageHeaderSavedPageLink({
  mobileContent,
  renderNewNavLinksDesign,
}: {
  mobileContent?: ReactNode
  renderNewNavLinksDesign?: boolean
}) {
  const isRentPdpDesignUpdate =
    useFeatureVariable<number>(['rent_pdp_design_updates', 'version'], 0) === 1

  const favoritesCount = useAtomValue(userTotalSavedListings)
  const hasFavorites = favoritesCount > 0
  const favoriteIcon = useMemo(() => {
    return hasFavorites ? (
      <HeartFilledIcon
        className={styles.pageHeaderFavoriteFilledIcon}
        aria-hidden
      />
    ) : (
      <HeartIcon aria-hidden data-tid="empty-heart" />
    )
  }, [hasFavorites])

  return (
    <a
      className={clsx(
        styles.pageHeaderFavoriteLink,
        mobileContent && styles.pageHeaderHiddenWithMobileContent,
        renderNewNavLinksDesign && styles.pageHeaderLink,
        isRentPdpDesignUpdate && styles.rentPdpDesignUpdate
      )}
      aria-label="Saved properties"
      href="/saved"
      data-tag_item="favorites_icon_header"
      data-tid="favorites-icon-btn"
    >
      {renderNewNavLinksDesign ? (
        <span className={styles.pageHeaderSavedApts}>
          Saved Apts{' '}
          <span className={styles.pageHeaderCount}>
            {hasFavorites && `(${favoritesCount})`}
          </span>
        </span>
      ) : (
        <>
          {favoriteIcon}
          {(hasFavorites || isRentPdpDesignUpdate) && (
            <span
              className={clsx(
                styles.pageHeaderCount,
                isRentPdpDesignUpdate && styles.pageHeaderCountUpdate
              )}
            >
              {favoritesCount}
            </span>
          )}
        </>
      )}
    </a>
  )
}
