import {
  createMinSqFtSlug,
  createMaxPriceSlug,
  createMinPriceSlug,
  bedSlugMap,
  bathSlugMap,
  petPolicySlugMap,
  hotDealsSlug,
  amenitySlugMap,
  propertyTypeSlugMap,
  maxPriceSlugs,
  priceValues,
} from '@brand/search/refinements'
import { getSearchPageUrlFromLocation } from '../../search/utils/get-search-page-url-from-location'
import {
  PropertyTypeFilter,
  LocationSearchTypes,
  type LocationSearchResult,
  PetPolicyFilter,
  Amenity,
  RentPricePreference,
} from '../../../__generated__/api-types'
import {
  REFINEMENT_SEPARATOR,
  PROPERTY_TYPES_SEPARATOR,
} from '@brand/search/refinements.const'

// See https://redfin.atlassian.net/browse/WEB-13609

const searchCriteriaQueryParams = [
  'locationSlug',
  'amenities',
  'baths',
  'beds',
  'locationType',
  'locationSeoPath',
  'petPolicies',
  'priceMax',
  'priceMin',
  'propertyTypes',
  'squareFeetMin',
  'withDeals',
  'bbox',
  'moveInDate',
  'rentPricePreference',
]

const ZIP_REGEX = /\d{5}/g

export const addBrandPropertyTypeSeparator = (propertyTypes: string[]) =>
  propertyTypes.join(PROPERTY_TYPES_SEPARATOR)

export const addBrandRefinementSeparator = (refinement: string[]) =>
  refinement.join(REFINEMENT_SEPARATOR)

export const replaceMobileSpecificRefinements = (refinements: string) =>
  refinements
    .replaceAll(Amenity.WalkInClosets, Amenity.OversizedClosets)
    .replaceAll(PetPolicyFilter.CatsAndDogsOk, PetPolicyFilter.PetFriendly)

export const roundToNearestPriceValue = (price: number): number => {
  return priceValues.reduce((closestPrice, currentPrice) =>
    Math.abs(currentPrice - price) < Math.abs(closestPrice - price)
      ? currentPrice
      : closestPrice
  )
}

export const getRefinementsAsString = (
  searchParams: URLSearchParams,
  isPriceFilterUpdateEnabled?: boolean
) => {
  let refinements: string[] = []
  for (const [key, value] of searchParams) {
    switch (key) {
      case 'amenities':
        value
          .split(',')
          .forEach((amenity: string) =>
            refinements.push(amenitySlugMap[amenity])
          )
        break
      case 'petPolicies':
        refinements.push(petPolicySlugMap[value])

        break
      case 'baths':
        value
          .split(',')
          .forEach((bath: string) => refinements.push(bathSlugMap[bath]))
        break

      case 'beds':
        value
          .split(',')
          .forEach((bed: string) => refinements.push(bedSlugMap[bed]))
        break

      case 'squareFeetMin':
        refinements.push(createMinSqFtSlug(Number(value)))
        break

      case 'withDeals':
        if (value === 'true') refinements.push(hotDealsSlug)
        break

      case 'priceMax':
        if (value) {
          if (!isPriceFilterUpdateEnabled) {
            refinements.push(
              createMaxPriceSlug(roundToNearestPriceValue(Number(value)))
            )
          } else {
            refinements.push(createMaxPriceSlug(Number(value)))
          }
        }
        break

      case 'priceMin':
        if (value) {
          if (!isPriceFilterUpdateEnabled) {
            refinements.push(
              createMinPriceSlug(roundToNearestPriceValue(Number(value)))
            )
          } else {
            refinements.push(createMinPriceSlug(Number(value)))
          }
        }
        break

      case 'rentPricePreference':
        if (value === RentPricePreference.Cheap) {
          refinements.push(value)
          refinements = refinements.filter(
            (slug) => !maxPriceSlugs.includes(slug)
          )
        }
        break
    }
  }

  return addBrandRefinementSeparator(refinements.filter(Boolean))
}

export const getSavedSearchRedirectUrl = (
  urlSearchParam: string,
  isPriceFilterUpdateEnabled?: boolean
) => {
  const proxyUrlParams = new URLSearchParams(
    replaceMobileSpecificRefinements(urlSearchParam)
  )
  const locationType = proxyUrlParams.get('locationType') as LocationSearchTypes
  const propertyTypesParamValues =
    (proxyUrlParams.get('propertyTypes')?.split(',') as PropertyTypeFilter[]) ||
    []
  const bbox = proxyUrlParams?.get('bbox')?.split(',')?.map(Number)
  const seoPath = proxyUrlParams?.get('locationSeoPath') || ''
  const refinements = getRefinementsAsString(
    proxyUrlParams,
    isPriceFilterUpdateEnabled
  )
  const moveInDate = proxyUrlParams.get('moveInDate') || undefined
  let singlePropertyTypeFilterParam: string | undefined = undefined
  const location: Partial<LocationSearchResult> = {
    seoPath,
    bbox,
    type: locationType,
  }
  const sortByDistance = Boolean(proxyUrlParams.get('sortByDistance'))

  if (propertyTypesParamValues[0] === PropertyTypeFilter.Houses) {
    if (propertyTypesParamValues.length === 1) {
      singlePropertyTypeFilterParam =
        propertyTypeSlugMap[propertyTypesParamValues[0]]
    }
  }

  if (locationType === LocationSearchTypes.Zip) {
    const zipCode = seoPath.match(ZIP_REGEX)
    if (zipCode) {
      location.id = zipCode[0]
    }
  }

  const propertyTypes = addBrandPropertyTypeSeparator(
    // Force a different order of property type params, since apartments_condos_houses_townhouses
    // was a permanent redirect to /apartments on rent, prior to the update to the /apartments SRP
    // to show only apartments.
    propertyTypesParamValues
      .sort((a, b) => {
        const order = [
          PropertyTypeFilter.Apartments,
          PropertyTypeFilter.Townhouses,
          PropertyTypeFilter.Condos,
          PropertyTypeFilter.Houses,
        ]
        return order.indexOf(a) - order.indexOf(b)
      })
      .map((propertyFilter) => propertyTypeSlugMap[propertyFilter])
  )

  const searchUrl = getSearchPageUrlFromLocation({
    location,
    singlePropertyTypeFilterParam,
    propertyTypes,
    refinements,
    moveInDate,
    sortByDistance,
  })

  if (searchUrl) {
    // Maintain external query params, such as those related to the email marketing campaign.
    const [searchUrlPath, searchUrlQueryString] = searchUrl.split('?')
    const searchUrlParams = new URLSearchParams(searchUrlQueryString)

    Array.from(proxyUrlParams.entries()).forEach(([key, value]) => {
      if (!searchCriteriaQueryParams.includes(key)) {
        searchUrlParams.set(key, value)
      }
    })

    return Array.from(searchUrlParams.keys()).length
      ? `${searchUrlPath}?${searchUrlParams.toString()}`
      : searchUrlPath
  }

  return null
}
