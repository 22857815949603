import type { DetailPageBrandText } from '../../../features/detail/detail-page.types'

/**
 * @file Dynamic text for the AG brand.
 * To add or modify:
 * - AG and Rent files must contain same keys
 * - Update the TypeScript types first (to add type checks for both AG and Rent)
 * - You can use a plain string for simple text substitution, or a function to insert values into the string.
 * - Avoid putting business logic into the functions, this should only be used for formatting text.
 * - This file is for plain text only. For JSX or HTML, use slots instead.
 */

export const showSubheader = false

export const detailPageBrandText: DetailPageBrandText = {
  '3Dtour': 'Virtual Tours',
  hotDealsSectionName: 'Hot Deals',
  hotDealsBadge: 'Hot Deals',
  highlightsSectionName: 'Top Amenities',
  tourSectionName: 'Virtual Tours & Videos',
  gettingAroundSectionName: 'Getting Around',
  amenitiesSectionName: 'Amenities',
  internetProvidersSectionName: 'Internet',
  petPolicySectionName: (propertyName: string) =>
    `Pet Rules at ${propertyName}`,
  petPolicyAnchorNavLink: 'Pet Rules',
  petPolicyInitialFeeLabel: 'Initial Pet Fee',
  petPolicyMaximumPetsLabel: 'Maximum # of pets',
  petPolicyMaximumWeightLabel: 'Maximum weight',
  petPolicyAdditionalRentLabel: 'Additional rent',
  petPolicyDepositLabel: 'Deposit',
  placesNearbySectionName: 'Explore the Area',
  priceDropLabel: 'Lower Price',
  ratingReviewSectionName: 'Renter Reviews',
  ratingReviewSubheadingWithReview: 'Find out what others have to say',
  ratingReviewInternalLabel: 'ApartmentGuide',
  getAmenitiesSectionSubheading: (amenityCount = 0) =>
    `${amenityCount} Amenities`,
  getTourSectionSubheading: (propertyName: string) =>
    `Step inside your future home at ${propertyName}`,
  getGettingAroundSectionSubheading: ({ propertyName, walkScore }) =>
    `${propertyName} is: ${walkScore}`,
  formatBedText: (originalBedText: string) => originalBedText,
  formatBathText: (originalBathText: string) =>
    originalBathText.replace('Baths', 'Ba').replace('Bath', 'Ba'),
  totalCostAndFeesSectionName: 'Total Costs',
  offMarketBadgeText: 'Previously Available for Rent',
}
