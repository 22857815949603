import type { GraphqlRequesterOptions } from '@rentpath/graphql-requester'

const url =
  typeof document !== 'undefined'
    ? '/graphql/'
    : `${process.env.RENT_API_PROTOCOL || 'http'}://${
        process.env.RENT_API_HOST
      }/graphql/`

export const graphqlRequesterOptions: GraphqlRequesterOptions = {
  url,
  brand: 'ag',
  clientName: process.env.APPLICATION_ENVIRONMENT
    ? `ag-web-${process.env.APPLICATION_ENVIRONMENT}`
    : 'ag-web-unknown-env',
}
