import clsx from 'clsx'
import { ModalName } from '../../modals/modals.config'
import { useOpenModal } from '../../modals/use-open-modal'
import { memo, useCallback } from 'react'
import { yieldOrContinue } from 'main-thread-scheduling'

export type AuthModalTriggersProps = {
  logInLinkClassName?: string
  signUpLinkClassName?: string
  onLoginClick?: () => void
  onSignUpClick?: () => void
  logInDataTid?: string
  signUpDataTid?: string
  wrapperClassName?: string
  wrapperLogInClassName?: string
  wrapperSignUpClassName?: string
  hideSignUp?: boolean
}

export const AuthModalTriggers = memo(function AuthModalTriggers(
  props: AuthModalTriggersProps
) {
  const openModal = useOpenModal()
  const { onLoginClick, onSignUpClick } = props

  const handleLoginClick = useCallback(
    async function handleLoginClick() {
      onLoginClick?.()

      openModal({
        id: ModalName.SIGN_IN,
        props: {},
      })

      await yieldOrContinue('interactive')
      window.eventTracker?.track('click', {
        section: 'menu',
        item: 'log_in',
      })
    },
    [onLoginClick, openModal]
  )

  const handleSignupClick = useCallback(
    async function handleSignupClick() {
      onSignUpClick?.()

      openModal({
        id: ModalName.SIGN_UP,
        props: {},
      })

      await yieldOrContinue('interactive')
      window.eventTracker?.track('click', {
        section: 'menu',
        item: 'sign_up',
      })
    },
    [onSignUpClick, openModal]
  )

  return (
    <>
      <li className={clsx(props.wrapperClassName, props.wrapperLogInClassName)}>
        <button
          className={props.logInLinkClassName}
          onClick={handleLoginClick}
          data-tag_action="ignore"
          data-tid={props.logInDataTid}
          type="button"
        >
          Log In
        </button>
      </li>

      {!props.hideSignUp && (
        <li
          className={clsx(props.wrapperClassName, props.wrapperSignUpClassName)}
        >
          <button
            className={props.signUpLinkClassName}
            onClick={handleSignupClick}
            data-tag_action="ignore"
            data-tid={props.signUpDataTid}
            type="button"
          >
            Sign Up
          </button>
        </li>
      )}
    </>
  )
})
