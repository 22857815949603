/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx'
import type { ComponentProps, ReactNode, JSX } from 'react'
import { forwardRef } from 'react'
import styles from './icon-button.module.css'
import { Button } from '../button/button'

export type IconButtonOwnProps = {
  icon: JSX.Element
  iconPosition?: 'left' | 'right'
  /** Label for the icon button */
  label: ReactNode
  /** Optionally style the label element */
  labelClassName?: string
  /** Whether to show the label as text or `aria-label` */
  showLabel?: boolean
}

type IconButtonProps = IconButtonOwnProps & ComponentProps<typeof Button>

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton(
    {
      icon,
      iconPosition,
      label,
      showLabel = false,
      labelClassName,
      ...restProps
    },
    ref
  ) {
    function renderLabel() {
      if (!labelClassName && showLabel) {
        return label
      }

      return (
        <span
          aria-hidden={!showLabel}
          className={clsx(labelClassName, !showLabel && 'sr-only')}
        >
          {label}
        </span>
      )
    }

    return (
      <span
        className={clsx(
          styles.iconButton,
          iconPosition === 'right' && styles.iconButtonReversed
        )}
      >
        <Button
          ref={ref}
          aria-label={restProps['aria-label'] || showLabel ? undefined : label}
          {...restProps}
        >
          <span className={styles.iconButtonIcon}>{icon}</span>
          {renderLabel()}
        </Button>
      </span>
    )
  }
)
