const propertyTypeSlug =
  ':propertyTypes(apartments|condos|houses|townhomes|(?:apartments|condos|houses|townhomes){1}(?:_apartments|_condos|_houses|_townhomes){1,3})'

const poiSearchPaths = [
  `/p/${propertyTypeSlug}/:state/:location/:poi{/:refinements}?{/page-:page}?/`,
]

const schoolSearchPaths = [
  `/${propertyTypeSlug}/schools/:state/:location/:school{/:refinements}?{/page-:page}?/`,
]

const schoolDistrictSearchPaths = [
  `/${propertyTypeSlug}/schools/:state/districts/:schoolDistrict{/:refinements}?{/page-:page}?/`,
]

const hoodSearchPaths = [
  `/neighborhoods/${propertyTypeSlug}/:state/:location/:hood{/:refinements}?{/page-:page}?/`,
]

const zipSearchPaths = [
  `/zip/:zipCode-${propertyTypeSlug}-For-Rent{/:refinements}?{/page-:page}?/`,
]

const countySearchPaths = [
  `/${propertyTypeSlug}/counties/:state/:county{/:refinements}?{/page-:page}?/`,
]

const citySearchPaths = [
  `/${propertyTypeSlug}/:state/:location{/:refinements}?{/page-:page}?/`,
]

const searchPaths = [
  ...zipSearchPaths,
  ...poiSearchPaths,
  ...schoolSearchPaths,
  ...schoolDistrictSearchPaths,
  ...hoodSearchPaths,
  ...countySearchPaths,
  ...citySearchPaths,
]

module.exports = {
  searchPaths,
  propertyTypeSlug,
  poiSearchPaths,
  schoolSearchPaths,
  schoolDistrictSearchPaths,
  hoodSearchPaths,
  zipSearchPaths,
  countySearchPaths,
  citySearchPaths,
}
