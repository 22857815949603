import React from 'react'
import clsx from 'clsx'
import styles from './spinner.module.css'

export type SpinnerProps = {
  variant?: 'primary' | 'inverted'
}

export function Spinner(props: SpinnerProps) {
  const { variant } = props

  return (
    <div
      data-tid="spinner"
      role="status"
      className={clsx(
        styles.spinner,
        typeof variant === 'string' && styles[variant]
      )}
    >
      <span className="sr-only">Loading…</span>
    </div>
  )
}
