import { ReactComponent as CloseIcon } from '@brand/icons/close.svg'
import { IconButton } from '../../components/icon-button/icon-button'
import styles from '../modals/modal.module.css'
import type { ForwardedRef, ReactElement, ReactNode } from 'react'
import { useMemo, useCallback, forwardRef, cloneElement } from 'react'
import clsx from 'clsx'
import { yieldOrContinue } from 'main-thread-scheduling'

interface DialogModalCloseButtonProps {
  children?: ReactNode
  className?: string
  /**
   * If passed, the developer has control
   * over the close button's behavior.
   * They will have to manually close the modal
   * when implementing this prop.
   * @default () => {}
   */
  onClick?: () => void
  'data-tid'?: string
  'data-tag_item'?: string
  'data-tag_section': string
}

/**
 * A close button for the DialogModal component.
 *
 * `ref` if passed, the close button *will* close the modal.
 * The necessary `ref` passed needs to come from the DialogModal
 * component. Also, if the developer passed both
 * `ref` and `onClick`, there is no need to close the modal
 * manually in the `onClick` function.
 */
export const DialogModalCloseButton = forwardRef(
  function DialogModalCloseButton(
    props: DialogModalCloseButtonProps,
    ref: ForwardedRef<HTMLDialogElement>
  ) {
    const {
      onClick,
      'data-tid': tid,
      'data-tag_item': item,
      'data-tag_section': section,
    } = props

    const handleClose = useCallback(
      async function handleClose() {
        if (ref && 'current' in ref) {
          await yieldOrContinue('smooth')
          ref.current?.close('button')
        }
        await yieldOrContinue('smooth')
        onClick?.()

        // track close button click
        window.eventTracker?.track('click', {
          section,
          item: item || 'x',
        })
      },
      [ref, onClick, section, item]
    )

    const commonProps = useMemo(
      function commonProps() {
        return {
          // disable automated tracking in favor of
          // manual tracking so we have more control
          // over when it fires the tag
          ['data-tag_action']: 'ignore',
          ['data-tid']: tid ?? 'close-modal',
          onClick: handleClose,
        }
      },
      [tid, handleClose]
    )

    return (
      <span className={clsx(styles.closeButton, props.className)}>
        {props.children ? (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          cloneElement(props.children as ReactElement<any>, commonProps)
        ) : (
          <IconButton {...commonProps} icon={<CloseIcon />} label="Close" />
        )}
      </span>
    )
  }
)
