import { match } from 'path-to-regexp'
import { nearMe } from './near-me'

export const genericRewrites: Record<string, string> = nearMe

export const genericRewritesPaths = Object.keys(genericRewrites)

export const roomsForRentMatcher = match<{ state: string; location: string }>(
  '/apartments/:state/:location/rooms-for-rent{/page-:page}?/'
)
