import type { modalDefinitions } from './modal-definitions'

export enum ModalName {
  CONFIRM_DELETE_SAVED_SEARCH,
  EMAIL_CAPTURE,
  FORGOT_PASSWORD,
  ONBOARDING,
  REPORT_ABUSE,
  REVIEW,
  SAVED_SEARCH_CONFIRMATION_MODAL,
  SHARE,
  SIGN_IN,
  SIGN_UP,
  SRP_VIEWS_EMAIL_CAPTURE,
  TOUR_WIZARD,
  REQUEST_A_TOUR_WIZARD,
  SHARE_PROPERTY,
  MARKETO_FORM,
  INCOME_RESTRICTIONS_CLARIFICATION,
}

export type ModalId = keyof typeof modalDefinitions

type ModalProps = {
  [K in ModalId]: typeof modalDefinitions[K]['propTypes']
}

export type ModalConfigForId<T extends keyof ModalProps> = {
  id: T
  props: ModalProps[T]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Distribute<U extends keyof ModalProps> = U extends any
  ? ModalConfigForId<U>
  : never

export type ModalConfiguration = Distribute<ModalId>
