'use client'

import { memo } from 'react'
import clsx from 'clsx'
import styles from '@brand/slots/page-header/drawer-menu/drawer-menu.module.css'
import { AuthModalTriggers } from '../../user/auth-modals/auth-modal-triggers'
import { LogOutButton } from '../../user/log-out-button'
import { useUser } from '../../user/user.store'

export const DrawerMenuUserLinks = memo(function DrawerMenuUserLinks(props: {
  handleClose: () => void
}) {
  const user = useUser()

  return user?.id && user.givenName ? (
    <>
      <li className={styles.divider}>
        <span
          className={clsx(styles.item, styles.profileName, styles.noLinkCursor)}
        >
          {user.givenName}
        </span>
      </li>

      <li>
        <a
          href="/profile"
          className={styles.item}
          data-tid="menu-section-profile-link"
        >
          Profile
        </a>
      </li>

      <li>
        <LogOutButton
          className={styles.item}
          data-tid="menu-section-sign-out-btn"
          onClick={props.handleClose}
        >
          Log Out
        </LogOutButton>
      </li>
    </>
  ) : (
    <AuthModalTriggers
      logInLinkClassName={styles.item}
      signUpLinkClassName={styles.item}
      onLoginClick={props.handleClose}
      onSignUpClick={props.handleClose}
      logInDataTid="menu-section-log-in"
      signUpDataTid="menu-section-sign-up"
    />
  )
})
